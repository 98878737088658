import { CommonUtil } from "../../utils/CommonUtil";
import { Dropdown } from 'primereact/dropdown';




const genOptions = Array.from({ length: 999 }).map((i, index) => {
    return {label: CommonUtil.ordinalSuffixOf(index + 1)+" Generation", value: (index + 1)}
});


interface Props {
    generationNo?: number;
    onChange: (generationNo: number) => void;
    className?: string;
}

const GenerationDropdown = (props: Props) => {
    return <Dropdown className={"genDropdown "+(props.className? props.className: '') } panelClassName="genDropdownPanel" placeholder="Select a Generation"
                value={props.generationNo} options={genOptions} 
                onChange={(e) => props.onChange(Number(e.value))} 
                virtualScrollerOptions={{ itemSize: 34}} />
};

export default GenerationDropdown;