import { createContext, useContext, useMemo, useState } from "react";
import Message, { MessageType } from "../../models/Message";
import AlertNotification from "./AlertNotification";
import WithAxios from "./WithAxios";



interface Props {
    children: any
}


interface AlertContextType {
    setAlertMessage: (value: Message) => void;
}

export const AlertContext = createContext({setAlertMessage: (value: Message) => {}} as AlertContextType);


const AlertHandler = (props: Props) => {
    const [alert, setAlert] = useState<Message>();


    const contextPayload:AlertContextType  = useMemo(() => {
        return {
                setAlertMessage: (value: Message) => {
                    setAlert(value);
                }
            };
    }, [setAlert]);


    // We wrap it in a useMemo for performance reasons. More here:
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
//   const contextPayload = React.useMemo(
//     () => ({ setErrorStatusCode }), 
//     [setErrorStatusCode]
//   );

    return <AlertContext.Provider value={contextPayload}>
                <AlertNotification message={alert!}/>
                <WithAxios>
                    {props.children}
                </WithAxios>
        </AlertContext.Provider>
}


export default AlertHandler;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAlertMessage = () => {
    const {setAlertMessage} = useContext(AlertContext);

    const setSuccesMsg = (value: string) => {
        setAlertMessage({message: value, type: MessageType.INFO});
    };

    const setErrorMsg = (value: string) => {
        setAlertMessage({message: value, type: MessageType.ERROR});
    };

    const setWarningMsg = (value: string) => {
        setAlertMessage({message: value, type: MessageType.WARN});
    };
    return {setAlertMessage, setSuccesMsg, setErrorMsg, setWarningMsg};
};