import { BrowserRouter} from 'react-router-dom';
import './App.css';
import AppRoutes from './AppRoutes';
import AlertHandler from './components/common/AlertHandler';
import Footer from './components/common/Footer';
import GenNavBar from './components/common/GenNavBar';
import UserSessionHandler from './components/common/UserSessionHandler';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <UserSessionHandler>
            <AlertHandler>
                <GenNavBar />
                <AppRoutes />
                <Footer />
            </AlertHandler>
          </UserSessionHandler>
        </BrowserRouter>
    </div>
  );
}

export default App;
