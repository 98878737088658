import React from "react";
import { useLocation, useParams } from "react-router-dom";
import AddIndividualForm from "../components/AddIndividualForm/AddIndividualForm";
import AddWifeForm from '../components/AddIndividualForm/AddWifeForm';
import { CommonUtil } from "../utils/CommonUtil";


const AddIndividualPage = () => {
    const loc = useLocation();
    const param = useParams();
    const userId = param["id"] as unknown as string;

    if(loc.pathname.endsWith("/wife")) {
        const queryParams = CommonUtil.getQueryParams(loc);
        return <AddWifeForm husbandId={userId} husbandName={queryParams.get('husbandName') ?? ''}/>
    }
        
    
    return <AddIndividualForm />
};

export default AddIndividualPage;