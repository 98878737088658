import Tree from "react-d3-tree";
import { Orientation, RawNodeDatum, TreeLinkDatum, TreeNodeDatum } from "react-d3-tree/lib/types/common";
import { Gender, Individual } from "../models/Individual";


declare global {
    interface Window {
        res: any,
      }
}

export class TreeUtil {
    static readonly NODE_SIZE: number = 50;

    static readonly HUSBAND_X_INIT: number = -105;

    static getHusbandX(name: string) {
        const charFactor = 0;
        return TreeUtil.HUSBAND_X_INIT - charFactor;
    }

    static getWifeX(husbandIconX:number) {
        return Math.abs(husbandIconX) - Math.abs(TreeUtil.HUSBAND_X_INIT) + Math.abs(TreeUtil.HUSBAND_X_INIT) - (TreeUtil.NODE_SIZE/2);
    }


    static expandUsersByWives(users: Individual[]):Individual[] {
        let expandedUsers:Individual[] = [];

        users.forEach(u => {
            TreeUtil.expandUserByWives(u).forEach(u2 => {
                expandedUsers.push(u2);
            })
        });

        return expandedUsers;
    }

    static expandUserByWives(user: Individual):Individual[] {
        let expandedUsers:Individual[] = [];

        if(user.children && user.children!.length > 0) {
            user.children = TreeUtil.expandUsersByWives(user.children!);
        }

        if(user.wives !== undefined && user.wives.length > 1) {
            user.wives.sort((w1, w2) => w1.rank - w2.rank);

            const wives = user.wives;
            //create one clone user with one wife per every other wives
            
            for(var i = 0;i < wives.length;i++) {
                const w = wives[i];
                w.index = i;
                const userClone = {...user};
                userClone.wives = [w];
                expandedUsers.push(userClone);
            }
        } else {
            expandedUsers.push(user);
        }

        return expandedUsers;
    }

    

    static userToRawNode(user: Individual, isAllSiblingsDead: boolean): RawNodeDatum {
        const attributes: Record<string, any> = {'user': user};
        const hasWife = user.wives && user.wives.length > 0;
        const wifeId = hasWife? user.wives![0].id: '';

        if(user.isDead === true && !isAllSiblingsDead)
            attributes[hasWife? 'markHusbandAsDead': 'markAsDead'] = true;

        const isAllChildrenDead = TreeUtil._isAllChildrenDead(user.children);
        let children: Individual[] = undefined!;
        if(user.children) {
            //set all dead by default if it has any children
            children = TreeUtil._sortAndFilterChildren(user.children, wifeId);
        }

        const data: RawNodeDatum = {
            name: user.name + "_" + wifeId,
            attributes: attributes,
            children: children !== undefined? children!.map(u => TreeUtil.userToRawNode(u, isAllChildrenDead)) as RawNodeDatum[]: undefined
        };
    
        return data;
    }

    static descendantResToTreeNodes(currNode: TreeNodeDatum, descendants: Individual[]): TreeNodeDatum[] {
        const groupedByFather = descendants.reduce((group: any, d: Individual) => {
            const fatherId = d!.fatherId ?? '';
            
            group[fatherId] = group[fatherId] ?? [];
            group[fatherId].push(d);
            return group;
        }, {});

        const toTreeNode = (user: Individual, isAllSiblingsDead: boolean): TreeNodeDatum => {
            const attributes: Record<string, any> = {'user': user};

            const hasWife = user.wives && user.wives.length > 0;
            const wifeId = hasWife? user.wives![0].id: '';

            if(user.isDead === true && !isAllSiblingsDead)
                attributes[hasWife? 'markHusbandAsDead': 'markAsDead'] = true;

            const node = {name: user.name + "_" + wifeId, attributes: attributes, __rd3t: {
                id: new Date().getTime() + "-" +  Math.random() * 2,
                depth: currNode.__rd3t.depth + user.treeDepth! + 1,
                collapsed: true,
                
            }} as TreeNodeDatum;

            const children = groupedByFather[user.id] as Individual[];

            if(children) {
                const isAllChildrenDead = TreeUtil._isAllChildrenDead(children);
                const childrenNodes = TreeUtil._sortAndFilterChildren(children, wifeId).map((c: Individual) => toTreeNode(c, isAllChildrenDead));
                node.children = childrenNodes;
            }

            return node;
        }

        const currUser = currNode.attributes!['user'] as unknown as Individual;
        const children = groupedByFather[currUser.id] as Individual[];
        if(children) {
            const wifeId = (currUser.wives)? currUser.wives[0].id: '';
            const isAllChildrenDead = TreeUtil._isAllChildrenDead(children);
            const childrenNodes = TreeUtil._sortAndFilterChildren(children, wifeId).map((c: Individual) => toTreeNode(c, isAllChildrenDead));
            return childrenNodes;
        }

        return [];
    }


    static _isAllChildrenDead(children?: Individual[]): boolean {
        let isAllChildrenDead = false;
        if(children) {
            //set all dead by default if it has any children
            isAllChildrenDead = true;

            children.forEach(c => {
                if(c.isDead !== true)
                    isAllChildrenDead = false;
            });
        }

        return isAllChildrenDead;
    }

    static _sortAndFilterChildren(children: Individual[], motherId: string) {
        // sort by birthRank ASC
        children.sort((u1, u2) => u1.birthRank - u2.birthRank);

        return children.filter(c => {
            if(motherId === '') return true;
            return c.motherId === motherId;
        });
    }

    static getDynamicPathClass ({ _source, target }: any, _orientation: any) {
        if (!target.children) {
          // Target node has no children -> this link leads to a leaf node.
          return 'link__to-leaf';
        }
    
        // Style it as a link connecting two branch nodes by default.
        return 'link__to-branch';
    };


    static findNodeById(id: string, tree: Tree): TreeNodeDatum | undefined{
        let foundNode: TreeNodeDatum | undefined;
        
        for(var i = 0; i < tree.state.data.length;i++) {
            const node = tree.state.data[i];
            foundNode = TreeUtil._findNode(id, node);

            if(foundNode)
                break;
        }

        return foundNode;
    }

    static _findNode(id: string, node: TreeNodeDatum): TreeNodeDatum | undefined {
        if(node.__rd3t.id === id) return node;

        if(node.children) {
            for(var i in node.children) {
                var cNode = node.children[i as unknown as number];
    
                var foundNode = TreeUtil._findNode(id, cNode);
    
                if(foundNode)
                    return foundNode;
            }
        }
    }

    /** Learn more about SVG path and its syntax here: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths */
    static getCoupleConnectingPath(husbandIconX: number, wifeIconX: number):string {
        const connectorBoxX = husbandIconX + (TreeUtil.NODE_SIZE/2);
        const connectorBoxSize = wifeIconX - husbandIconX;

        let connectingPath = "M "+connectorBoxX+" "+TreeUtil.NODE_SIZE+" v"+(TreeUtil.NODE_SIZE/2)+" h"+connectorBoxSize + " v-"+(TreeUtil.NODE_SIZE/2);

        return connectingPath;
    }

    static getWifeOnlyConnectingPath(husbandIconX: number, wifeIconX: number):string {
        const connectorBoxX = husbandIconX + (TreeUtil.NODE_SIZE/2);
        const connectorBoxSize = wifeIconX - husbandIconX;

        //figure out the max horizontal line to the previous wife node 
        const connectingPath = "M "+connectorBoxX+" "+TreeUtil.NODE_SIZE+" v"+(TreeUtil.NODE_SIZE/2)+" h-"+Math.min(connectorBoxSize * 2, 320);
        return connectingPath;
    }

    static getDiagonalPath(userIconX: number, gender: Gender):string {
        let originX = userIconX;
        let originY = TreeUtil.NODE_SIZE;

        const destX = originX + TreeUtil.NODE_SIZE;
        let destY = 0;

        if(gender === Gender.M) {
            originX -= 5;
            originY -= 10;
            destY += 10;
        }

        const connectingPath = "M "+originX+","+originY+" L"+destX+","+destY;
        return connectingPath;
    }

    /** Learn more about SVG path and its syntax here: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths*/
    static customPathFunction(linkDatum: TreeLinkDatum, orientation: Orientation): string {
        const { source, target } = linkDatum;
        
        const sourceUser = source.data.attributes!['user'] as unknown as Individual;
        const isSourceCollapsed = source.data.__rd3t.collapsed;
        const isSourceCouple = sourceUser.wives! && sourceUser.wives!.length > 0;
    
        const targetUser = target.data.attributes!['user'] as unknown as Individual;
        // const isTargetCollapsed = target.data.__rd3t.collapsed;
        const isTargetCouple = targetUser.wives! && targetUser.wives!.length > 0;
        
        
        //a horizontal line is drawn if there are more than 1 children
        let hasHLine = source.children && source.children.length > 1;

        //it is also drawn in below exceptional case
        if(!isSourceCouple && isTargetCouple) {
            hasHLine = true;
        }

        let hDistance:number = hasHLine? target.x - source.x: 0;
        let vDistance:number = isSourceCollapsed? 0: (target.y - source.y - TreeUtil.NODE_SIZE);
        let vDistance2:number = 0;
    
        if(hasHLine) {
            vDistance = vDistance/2;
            vDistance2 = vDistance;
        }

        let originX = source.x;
        let originY = source.y + TreeUtil.NODE_SIZE;
    
        //start Y sligth below to address the connector path
        if(isSourceCouple) {
            originY += TreeUtil.NODE_SIZE/2;
        }

        if(isTargetCouple) {
            const husbandNodeX = hDistance + (TreeUtil.NODE_SIZE/2) + TreeUtil.getHusbandX(targetUser.name);
            
            if(hasHLine) {
                hDistance = husbandNodeX;
            } else {
                originX += husbandNodeX;
            }
        }
        
        const hLine = hDistance !== 0? 'h'+hDistance: '';
        const vLine = vDistance !== 0? 'v'+vDistance: '';
        const vLine2 = vDistance2 !== 0? 'v'+vDistance2: '';
    
        const path = `M${originX},${originY} ${vLine} ${hLine} ${vLine2}`;

        // console.log("source", source, sourceUser.name, source.x, source.y);
        // console.log("target", target, targetUser.name, target.x, target.y);
        // console.log(path);
        return path;
      };
    
}