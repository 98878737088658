import React from "react";
import { useParams } from "react-router-dom";
import IndividualDetail from "../components/IndividualDetail/IndividualDetail";


const IndividualDetailPage = () => {
    const param = useParams();
    const userId = param["id"] as unknown as string;
    
    return <IndividualDetail userId={userId} />
};

export default IndividualDetailPage;