import axios, { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from "axios";


class HttpService {
    _baseUrl: string;
    _http: AxiosInstance;

    constructor() {
        this._baseUrl = process.env.REACT_APP_API_URL!;

        this._http = axios.create({
            baseURL: this._baseUrl,
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json"
            }
        });
    }

    getRequestInterceptor(): AxiosInterceptorManager<AxiosRequestConfig> {
        return this._http.interceptors.request;
    }

    getResponseInterceptor(): AxiosInterceptorManager<AxiosResponse> {
        return this._http.interceptors.response;
    }
    
    async get(url: string):Promise<any> {
        const res:AxiosResponse = await this._http.get(url);
        return await res.data;
    }

    async getCustom(url: string, config: AxiosRequestConfig):Promise<any> {
        const res:AxiosResponse = await this._http.get(url, config);
        return await res.data;
    }

    async post(url: string, data: any):Promise<any> {
        const res:AxiosResponse = await this._http.post(url, data);
        return res.data;
    }

    async multiPartPost(url: string, formData: FormData):Promise<any> {
        const res:AxiosResponse = await this._http.post(url, formData, 
                                                    {headers: {'Content-Type': 'multipart/form-data'}});
        return res.data;
    }

    async delete(url: string):Promise<any> {
        const res:AxiosResponse = await this._http.delete(url);
        return await res.data;
    }
}



export default new HttpService();