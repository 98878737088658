import { Button, Card, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Individual } from "../../models/Individual";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import GenerationService from "../../services/GenerationService";
import { useAlertMessage } from "../common/AlertHandler";
import { GenTag } from "../common/IndividualTag";

interface Props {
    individual?: Individual;
}

const EditGenerationForm = (props: Props) => {
    const nav = useNavigate(); 
    const {setSuccesMsg} = useAlertMessage();
    
    const individual = props.individual!;
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState<string>('');
    const backLinkUrl = individual? "/user-each/"+individual.id: "#";
    

    const onSubmit = () => {
        if(!value)
            return;

        setSubmitting(true);
        const submitData = async (newGen: number) => {
            await GenerationService.updateGenerationNo(individual.id, newGen);
            setSubmitting(false);
            setSuccesMsg("Generation Number updated successfully.");
            
            nav("/user-each/"+individual.id);
        }

        submitData(Number(value));
    };

    return <Card className="mt-4">
            <Card.Header as="h4">{individual? <Link to={backLinkUrl}>{decodeURI(individual.name)}</Link>: ""} Generation</Card.Header>
            <Card.Body>
                <div className="d-flex flex-row align-items-center">
                    <span style={{marginRight: '15px'}}>Current Generation: </span>
                    <GenTag generationNo={individual?.generationNo}/>
                </div>
                <Form.Group className="my-3 d-flex flex-row align-items-center">
                    <Form.Label style={{marginRight: '15px'}}>Update Generation:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Gen No." style={{width: '20%'}} 
                                value={value} onChange={(e) => setValue(e.target.value)}/>
                    
                </Form.Group>
              
                <Button variant="primary" size="sm" type="button" onClick={onSubmit} style={{width: '20%'}} disabled={submitting} >
                    {submitting? "Updating...": "Update Generation"}
                </Button>
            </Card.Body>
        </Card>
}

export default EditGenerationForm;