
import { useEffect, useState } from "react"
import { Card } from "react-bootstrap";
import GenerationDropdown from "../components/common/GenerationDropdown";
import GenerationTree from "../components/GenerationTree/GenerationTree"
import { Individual } from "../models/Individual";
import GenerationService from "../services/GenerationService";
import { CommonUtil } from "../utils/CommonUtil";




const GenerationTreePage = () => {
    const title: string = "Hyonjan Genealogy";

    const [genNo, setGenNo] = useState<number>(1);
    const [users, setUsers] = useState<Individual[] | null>(null);

    useEffect(() => {
        const fetchUser = async (genNo: number) => {
            setUsers(null);
            let genUsers = await GenerationService.getIndividuals(genNo);
            setUsers(genUsers);
        };
        
        if(genNo)
            fetchUser(genNo!);

    }, [genNo]);
    

    return <div className="container">
        <Card>
            <Card.Header as="h4">
                <div className="mb-3 d-flex flex-row justify-content-center align-items-baseline">
                    <GenerationDropdown generationNo={genNo} onChange={(v) => setGenNo(v)} />
                    <span style={{marginLeft: '10px', fontSize: '80%', color: 'gray'}}>Tree</span>
                </div>
            </Card.Header>
            <Card.Body>
                <GenerationTree title={title} users={users} emptyMessage={"No "+CommonUtil.ordinalSuffixOf(genNo)+" generation users found."}/>
            </Card.Body>
        </Card>
        
    </div>
}

export default GenerationTreePage;