import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import * as Yup from 'yup';

import './AddIndividualForm.css';
import { Button, Card } from "react-bootstrap";
import GenerationService from "../../services/GenerationService";
import { useNavigate, Link } from "react-router-dom";
import { Wife } from "../../models/Wife";
import { ArrowLeft } from "react-bootstrap-icons";
import { useAlertMessage } from "../common/AlertHandler";

interface WifeFormValues {
    name: string;
    permAddress?: string;
    isMarriageFormal?: string;
    rank: number;
}


const getInitialValues = ():WifeFormValues => {
    const initialValues:WifeFormValues = {
      name: '', permAddress: '',
      isMarriageFormal: '',
      rank: 1
    };
  
    return initialValues;
}

const validationSchema = () => {
    return Yup.object({
        name: Yup.string().required('Enter user name'),
        rank: Yup.string().required('Enter Rank No'),
        isMarriageFormal: Yup.string().required('Select Marriage Formality')
    });
}

interface TheWifeFormProps {
  husbandId: string;
  husbandName: string;
}


const TheWifeForm = (props: TheWifeFormProps) => {
    const formikContext: FormikProps<WifeFormValues> = useFormikContext();
  
  
    return <Form className='individualForm d-flex flex-column'>
            <div className='fieldGroups d-flex flex-row'>
              <div>
                <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage name="name" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
  
              <div>
                  <label htmlFor="permAddress" className="form-label">Permanent Address</label>
                  <Field name="permAddress" type="text" className="form-control" />
                  <ErrorMessage name="permAddress" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
              </div>
  
              <div>
                <label className="form-label">Is Marriage Formal? <span className="text-danger">*</span></label>
                <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="formalInput">Yes</label> <Field type="radio" id="formalInput" name="isMarriageFormal" value="true" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="inFormalInput">No</label> <Field type="radio" id="inFormalInput" name="isMarriageFormal" value="false" /></div>
                </div>

                <ErrorMessage name="isMarriageFormal" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
              </div>
            </div>
            
            <div className='fieldGroups d-flex flex-row'>
                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="rank" className="form-label">Rank No <span className="text-danger">*</span></label>
  
                  <Field name="rank" type="number" className="form-control" />
                  <ErrorMessage name="rank" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
                </div>
            </div>
  
            <hr />
            <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={formikContext.isSubmitting}>
                {formikContext.isSubmitting? "Submitting": "Submit"}
            </Button>
          </Form>
}




const AddWifeForm = (props: TheWifeFormProps) => {
    const nav = useNavigate();
    const initialValues:WifeFormValues = getInitialValues();
    const {setSuccesMsg} = useAlertMessage();

    const onFormSubmit = ((values: WifeFormValues, helper: FormikHelpers<WifeFormValues>) => {
      // alert(JSON.stringify(values));

      const wife:Wife = {id: '', name: values.name, rank: values.rank, 
                        permAddress: values.permAddress, 
                        isMarriageFormal: values.isMarriageFormal === 'true'}

      const submitData = async (husbandId: string, wife: Wife) => {
        try {
          await GenerationService.addWife(husbandId, wife);
          helper.setSubmitting(false);
          setSuccesMsg("Wife added successfully.");

          nav("/user-each/"+husbandId);
        } catch(error: any) {
          helper.setSubmitting(false);
        }
        
      };

      submitData(props.husbandId, wife);
    });

    const backLinkUrl = "/user-each/"+props.husbandId;
    return <div className="container">
        <Link className="btn btn-secondary btn-sm mb-3" to={backLinkUrl}><ArrowLeft /> Go Back</Link>
        <Card>
            <Card.Header as="h4"><Link to={backLinkUrl}>{decodeURI(props.husbandName)}</Link> / Add Wife</Card.Header>
            <Card.Body>
              <Formik initialValues={initialValues} validationSchema={validationSchema} 
                onSubmit={onFormSubmit}>
                  <TheWifeForm {...props} />
              </Formik>
            </Card.Body>
        </Card>
    </div>
};

export default AddWifeForm;