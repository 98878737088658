import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import * as Yup from 'yup';

import './AddIndividualForm.css';
import { Button, Card } from "react-bootstrap";
import { Gender, Individual } from "../../models/Individual";
import GenerationService from "../../services/GenerationService";
import { useLocation, Link } from "react-router-dom";
import { CommonUtil } from '../../utils/CommonUtil';
import { Wife } from "../../models/Wife";
import { ArrowLeft } from "react-bootstrap-icons";
import ReviewIndividualForm from "./ReviewIndividualForm";


interface IndividualFormValues {
  name: string;
  about: string;
  generationNo?: number;
  phone?: string;
  permAddress: string;

  dobMonth?: string;
  dobYear?: string;
  dobDate?: string;

  dodDate?: string;
  dodMonth?: string;
  dodYear?: string;

  isDead?: string;

  fatherId?: string;
  motherId?: string;
  gender?: Gender;
  birthRank?: number;
}


const getInitialValues = (fatherId: string, gender?: Gender):IndividualFormValues => {
  const initialValues:IndividualFormValues = {
    name: '', about: '', phone: '', permAddress: '',
    dobMonth: '', dobYear: '', dobDate: '',
    dodMonth: '', dodYear: '', dodDate: '',
    isDead: 'false',
    generationNo: 1,
    fatherId: fatherId,
    motherId: '',
    gender: gender,
    birthRank: 1
  };

  return initialValues;
}

const validationSchema = () => {
  return Yup.object({
      name: Yup.string().required('Enter user name'),
      about: Yup.string().required('Enter user description'),
      permAddress: Yup.string().required('Enter user address'),
      generationNo: Yup.string().required('Enter Generation No.'),
      
      dobMonth: Yup.string().when('dobDate', {
        is: (dobDate: string) => {
          return dobDate !== undefined && dobDate !== '';
        },
        then: Yup.string().required('Enter Date of Birth'),
        otherwise: Yup.string()
      }),
      dobYear: Yup.string().when('dobMonth', {
        is: (dobMonth: string) => {
          return dobMonth !== undefined && dobMonth !== '';
        },
        then: Yup.string().required('Enter Date of Birth'),
        otherwise: Yup.string()
      }),

      birthRank: Yup.string().required('Enter Birth Rank'),
      phone: Yup.string().length(10, 'Phone must be a 10 digit number'),
      gender: Yup.string().required('Select a gender'),
      motherId: Yup.string().when('fatherId', {
        is: (fatherId: string) => {
          return fatherId !== undefined && fatherId !== '';
        },
        then: Yup.string().required('Select Mother'),
        otherwise: Yup.string()
      })
  });
}

interface TheFormProps {
  individuals: Individual[];
  fatherId?: string;
  gender?: Gender;
}


const getMonthOptions = (monthPfx: string) => {
  return CommonUtil.MONTHS.map(m => {
    return <option value={m.value} key={monthPfx+"_"+m.name+"_opt"}>{m.name}</option>;
  });
}

const getDateOptions = (datePfx: string) => {
  return Array.from(Array(33).keys()).slice(1).map(d => {
    const value = (d < 10)? "0"+d: d;
    return <option value={value} key ={datePfx+"_"+value+"_dateopt"}>{d}</option>;
  });
}

const TheForm = (props: TheFormProps) => {
  const formikContext: FormikProps<IndividualFormValues> = useFormikContext();
  const [mothers, setMothers] = useState<Wife[]>([]);

  const individuals = props.individuals;
  individuals.sort((u1, u2) => {
    return u1.generationNo - u2.generationNo;
  });

  const selectedFatherId = formikContext.values.fatherId;
  useEffect(() => {
    if(selectedFatherId && individuals.length > 0) {
      const selectedFather:Individual = individuals.filter(u => u.id === selectedFatherId)[0];

      if(selectedFather.wives) {
        selectedFather.wives.sort((w1, w2) => w1.rank - w2.rank);
      }

      setMothers(selectedFather.wives? selectedFather.wives : []);
      formikContext.setFieldValue('motherId', '');
      formikContext.setFieldValue('generationNo', selectedFather.generationNo + 1);
    } else {
      setMothers([]);
      formikContext.setFieldValue('motherId', '');
      formikContext.setFieldValue('generationNo', '1');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFatherId, individuals]);

  const fatherOptions = individuals.map(u => {
    return <option value={u.id} key={u.id+"_father_select"}>{u.name} ({CommonUtil.ordinalSuffixOf(u.generationNo)} generation)</option>;
  })

  let motherOptions = mothers.map(w => {
        return <option value={w.id} key={w.id+"_wife_select"}>{w.name}</option>;
  });

  return <Form className='individualForm d-flex flex-column'>
          <div className='fieldGroups d-flex flex-row'>
            <div>
              <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
              <Field name="name" type="text" className="form-control" />
              <ErrorMessage name="name" render={
                (error: string) => <div className='text-danger'>{error}</div>
              }/>
            </div>

            <div>
                <label htmlFor="permAddress" className="form-label">Permanent Address <span className="text-danger">*</span></label>
                <Field name="permAddress" type="text" className="form-control" />
                <ErrorMessage name="permAddress" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
            </div>

            <div>
              <label htmlFor="phone" className="form-label">Phone Number</label>
              <Field name="phone" type="number" className="form-control" />
              <ErrorMessage name="phone" render={
                (error: string) => <div className='text-danger'>{error}</div>
              }/>
            </div>
          </div>
          
          <div className='fieldGroups d-flex flex-row'>
              <div style={{maxWidth: '50%'}}>
                <label htmlFor="about" className="form-label">Description <span className="text-danger">*</span></label>
                <Field name="about" as="textarea" type="text" rows="5" className="form-control" />
                <ErrorMessage name="about" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
          </div>

          <div className='fieldGroups d-flex flex-row'>
              <div className="dateOfInput">
                <label className="form-label">Date of Birth (B.S)</label>

                <div className="d-flex flex-row dobFields">
                  <div className="d-flex flex-column">
                    <label htmlFor="dobDate" className="form-label">Date</label>
                    <Field name="dobDate" as="select" className="form-control" >
                      <option value="">Select Date</option>
                      {getDateOptions("dob")}
                    </Field>
                  </div>
                  
                  <div className="d-flex flex-column">
                    <label htmlFor="dobMonth" className="form-label">Month</label>

                    <Field name="dobMonth" as="select" className="form-control" >
                      <option value="">Select Month</option>
                      {getMonthOptions("dob")}
                    </Field>
                  </div>
                  
                  <div className="d-flex flex-column">
                    <label htmlFor="dobYear" className="form-label">Year</label>
                    <Field name="dobYear" type="number" className="form-control" placeholder="E.g: 2047" />
                  </div>
                </div>
                
                <ErrorMessage name="dobDate" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>

                <ErrorMessage name="dobMonth" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
                
                <ErrorMessage name="dobYear" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>

              <div style={{marginTop: 'auto'}}>
                  <label htmlFor="isDead" className="form-label">Is Dead?</label>
                  <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="isYesDeadInput">Yes</label> <Field type="radio" id="isYesDeadInput" name="isDead" value="true" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="isNoDeadInput">No</label> <Field type="radio" id="isNoDeadInput" name="isDead" value="false" /></div>
                  </div>

                  
              </div>

              <div className="dateOfInput">
                <label className="form-label">Date of Demise (B.S)</label>

                <div className="d-flex flex-row dobFields">
                  <div className="d-flex flex-column">
                    <label htmlFor="dodDate" className="form-label">Date</label>
                    <Field name="dodDate" as="select" className="form-control"  disabled={formikContext.values.isDead !== 'true'}>
                      <option value="">Select Date</option>
                      {getDateOptions("dod")}
                    </Field>
                  </div>

                  <div className="d-flex flex-column">
                    <label htmlFor="dodMonth" className="form-label">Month</label>
              
                    <Field name="dodMonth" as="select" className="form-control" disabled={formikContext.values.isDead !== 'true'}>
                      <option value="">Select Month</option>
                      {getMonthOptions("dod")}
                    </Field>
                  </div>
                  
                  <div className="d-flex flex-column">
                    <label htmlFor="dodYear" className="form-label">Year</label>
                    <Field name="dodYear" type="number" className="form-control" placeholder="E.g: 2047" disabled={formikContext.values.isDead !== 'true'} />
                  </div>
                </div>
                
                <ErrorMessage name="dodYear" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
          </div>

          <div className='fieldGroups d-flex flex-row'>
            <div>
              <label htmlFor="gender" className="form-label">Gender <span className="text-danger">*</span></label>

              <Field name="gender" as="select" className="form-control" 
                    disabled={props.gender !== undefined}>
                    <option value="">Select Gender</option>
                      <option value={Gender.M}>{CommonUtil.toGenderStr(Gender.M)}</option>
                      <option value={Gender.F}>{CommonUtil.toGenderStr(Gender.F)}</option>
                      <option value={Gender.O}>{CommonUtil.toGenderStr(Gender.O)}</option>
                </Field>
              <ErrorMessage name="gender" render={
                (error: string) => <div className='text-danger'>{error}</div>
              }/>
            </div>

            <div>
                <label htmlFor="birthRank" className="form-label">Birth Rank<span className="text-danger">*</span></label>

                <Field name="birthRank" type="number" className="form-control" />
                <ErrorMessage name="birthRank" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
              <div>
                <label htmlFor="generationNo" className="form-label">Generation No <span className="text-danger">*</span></label>

                <Field name="generationNo" type="number" disabled={formikContext.values.fatherId !== ''} className="form-control" />
                <ErrorMessage name="generationNo" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
          </div>
          
          <div className='fieldGroups d-flex flex-row'>
            <div>
                <label htmlFor="fatherId" className="form-label">Father</label>

                <Field name="fatherId" as="select" className="form-control" 
                    disabled={props.fatherId !== undefined && props.fatherId !== ''}>
                  <option value="">Select Father</option>
                  {fatherOptions}
                </Field>
            </div>


            <div>
              <label htmlFor="motherId" className="form-label">Mother</label>

              <Field name="motherId" as="select" className="form-control" >
                <option value="">Select Mother</option>
                {motherOptions}
              </Field>

              <ErrorMessage name="motherId" render={
                (error: string) => <div className='text-danger'>{error}</div>
              }/>
            </div>

            <div className="invisible"></div>

          </div>
          <hr />
          <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={formikContext.isSubmitting}>
               Next
          </Button>
        </Form>
}





const formValuesToIndividual = (values: IndividualFormValues): Individual => {
  const individual: Individual =  {name: values.name, about: values.about, id: '', 
                                  generationNo: values.generationNo!, 
                                  permAddress: values.permAddress || '',
                                  gender: values.gender!, birthRank: values.birthRank!,
                                  isDead: values.isDead === 'true'};

  if(values.fatherId) individual.fatherId = values.fatherId;
  if(values.motherId) individual.motherId = values.motherId;
  
  if(values.dobMonth && values.dobYear && values.dobDate) 
        individual.dob = CommonUtil.getDOBStr(values.dobDate, values.dobMonth, values.dobYear);
  
  if(values.dodMonth && values.dodYear && values.dodDate) 
      individual.dod = CommonUtil.getDOBStr(values.dodDate, values.dodMonth, values.dodYear);

  if(values.phone) individual.phone = values.phone;
  if(values.permAddress) individual.permAddress = values.permAddress;

  return individual;
};

interface TitleProps {
  selectedFather?: Individual; 
}

const CardTitle = ({selectedFather}: TitleProps) => {

  let fatherLink = null;
  let addStr = "Add User";
  if(selectedFather) {
    fatherLink = <Link to={"/user-each/"+selectedFather.id}>{decodeURI(selectedFather.name)}</Link>;
    addStr = " / Add Child";
  }

  return <div>
      {fatherLink}{addStr}
    </div>
}

const AddIndividualForm = () => {
    const [individuals, setIndividuals] = useState([] as Individual[]);
    const [reviewIndividual, setReviewIndividual] = useState<Individual | null>(null);
    const loc = useLocation();

    const queryParams = CommonUtil.getQueryParams(loc);

    const fatherId = queryParams.get("fatherId") ?? '';
    const genderStr = queryParams.get("gender") ?? '';

    const gender: any = genderStr? CommonUtil.fromGenderStr(genderStr): undefined;

    const selectedFather = fatherId? individuals.filter(u => u.id === fatherId)[0]: undefined;
    const initialValues:IndividualFormValues = getInitialValues(fatherId, gender!);

    const onFormSubmit = ((values: IndividualFormValues, helper: FormikHelpers<IndividualFormValues>) => {
      const individual = formValuesToIndividual(values);
      setReviewIndividual(individual);
      helper.setSubmitting(false);
    });

    useEffect(() => {
      const loadIndividuals = async () => {
        const res: Individual[] = await GenerationService.getAllIndividuals();
        setIndividuals(res);
      }

      loadIndividuals();
    }, []);
    
    const backLinkUrl = fatherId? "/user-each/"+fatherId: "";
    return <div className="container">
        {backLinkUrl? <Link className="btn btn-secondary btn-sm mb-3" to={backLinkUrl}><ArrowLeft /> Go Back</Link>: null}
        <Card>
              <Card.Header as="h4">
                {
                  reviewIndividual === null &&
                  <CardTitle selectedFather={selectedFather!} />
                }

                {
                  reviewIndividual !== null &&
                  <div>Confirm User Detail</div>
                }
              </Card.Header>

              <Card.Body>
                <Formik initialValues={initialValues} validationSchema={validationSchema} 
                  onSubmit={onFormSubmit}>
                    <>
                      {
                        reviewIndividual === null &&
                        <TheForm individuals={individuals} fatherId={fatherId} gender={gender} />
                      }

                      {
                        reviewIndividual !== null &&
                        <ReviewIndividualForm individual={reviewIndividual} onEdit={() => {setReviewIndividual(null);}}
                            fatherId={fatherId} />
                      }
                    </>
                </Formik>
              </Card.Body>
        </Card>
    </div>
}

export default AddIndividualForm;