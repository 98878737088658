import { Button, Card } from "react-bootstrap";
import User, { UserRole } from "../../models/User";

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import './moderator.css';

import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useAlertMessage } from "../../components/common/AlertHandler";

interface UserFormValues {
    fullName: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    role: UserRole;
}

const getInitialValues = ():UserFormValues => {
    const initialValues:UserFormValues = {
        fullName: '', phoneNumber: '', password: '', confirmPassword: '',
        role: UserRole.MODERATOR
    };
  
    return initialValues;
}

const validationSchema = () => {
    return Yup.object({
        fullName: Yup.string().required('Enter user name'),
        phoneNumber: Yup.string().required('Enter a phone number').length(10, 'Phone must be a 10 digit number'),
        password: Yup.string().required('Enter a password'),
        confirmPassword: Yup.string().test('passwords-match', 'Passwords do not match', function(value){
            return this.parent.password === value
        })
    });
}


const TheForm = () => {
    const formikContext: FormikProps<UserFormValues> = useFormikContext();
    const nav = useNavigate();

    return <Form className='moderatorForm d-flex flex-column'>

            <div className='fieldGroups d-flex flex-column'>
                <div>
                    <label htmlFor="fullName" className="form-label">Name <span className="text-danger">*</span></label>
                    <Field name="fullName" type="text" className="form-control" />
                    <ErrorMessage name="fullName" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>

                <div>
                    <label htmlFor="phoneNumber" className="form-label">Phone Number <span className="text-danger">*</span></label>
                    <Field name="phoneNumber" type="text" className="form-control" />
                    <ErrorMessage name="phoneNumber" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>

                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="role" className="form-label">User Role</label>
                  <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="moderatorRole">Moderator</label> <Field type="radio" id="moderatorRole" name="role" value="MODERATOR" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="adminRole">Admin</label> <Field type="radio" id="adminRole" name="role" value="ADMIN" /></div>
                  </div>
                </div>

                <div>
                    <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                    <Field name="password" type="password" className="form-control" />
                    <ErrorMessage name="password" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>

                <div>
                    <label htmlFor="confirmPassword" className="form-label">Confirm Password <span className="text-danger">*</span></label>
                    <Field name="confirmPassword" type="password" className="form-control" />
                    <ErrorMessage name="confirmPassword" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>

                
            </div>
            

            <hr />

            <div className="d-flex flex-row">
                <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={formikContext.isSubmitting}>
                    {formikContext.isSubmitting? "Submitting": "Submit"}
                </Button>

                <Button variant="outline-dark" size="sm" type="button" style={{width: '20%', marginLeft: '20px'}} 
                        disabled={formikContext.isSubmitting}
                         onClick={() => {
                            nav("/moderators")
                         }}>
                    Go Back
                </Button>
            </div>
    </Form>
}

const AddModeratorPage = () => {
    const {setSuccesMsg} = useAlertMessage();
    const nav = useNavigate();

    const onFormSubmit = ((values: UserFormValues, helper: FormikHelpers<UserFormValues>) => {
        const user:User = {fullName: values.fullName, phoneNumber: values.phoneNumber, 
                            password: window.btoa(values.password), role: values.role};
  
        const submitData = async (u: User) => {
            UserService.register(u).then(res => {
                nav("/moderators");
                setSuccesMsg("User added successfully.");
                helper.setSubmitting(false);
            }).catch(err => {
                helper.setSubmitting(false);
            });
        };
        
        submitData(user);
      });

    const initialValues:UserFormValues = getInitialValues();
    return <div className="container">
        <Card className="mx-auto" style={{maxWidth: '500px'}}>
            <Card.Header as="h4">Add Moderator</Card.Header>
            <Card.Body>
                <Formik initialValues={initialValues} validationSchema={validationSchema} 
                    onSubmit={onFormSubmit}>
                    <TheForm />
                </Formik>
            </Card.Body>
        </Card>
    </div>
};

export default AddModeratorPage;