import { Wife } from "./Wife";

export enum Gender {
    M = "M", F = "F", O = "O"
}

export interface Individual {
    id: string;
    name: string;
    about: string;

    fatherId?: string;
    motherId?: string;

    birthRank: number;
    
    generationNo: number;
    treeDepth?: number;
    children?: Individual[];

    dob?: string;//MM-dd-YYYY
    permAddress: string;
    phone?: string;

    gender: Gender;

    
    wives?: Wife[];

    dod?: string;
    isDead?: boolean;
}