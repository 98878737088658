import { Dispatch, SetStateAction } from "react";
import Tree from "react-d3-tree";
import { CustomNodeElementProps, TreeNodeDatum } from "react-d3-tree/lib/types/common";
import { Link } from "react-router-dom";
import { Gender, Individual } from "../../models/Individual";
import { Wife } from "../../models/Wife";
import GenerationService from "../../services/GenerationService";
import { TreeUtil } from "../../utils/TreeUtil";
import { BirthRankTag, GenTag } from "../common/IndividualTag";


interface GenerationNodeProps extends CustomNodeElementProps {
    treeRef: any,
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    showBirthRank: boolean;
}

interface HushandWifeNodeProps extends GenerationNodeProps {
    wife: Wife
}

const NODE_SIZE = TreeUtil.NODE_SIZE;

// const handleLoadAncestor = (nodeDatum: TreeNodeDatum, treeRef: any, setIsLoading: Dispatch<SetStateAction<boolean>>) => {
//     const tree = treeRef.current as Tree;

//     const user: Individual = nodeDatum.attributes!["user"] as unknown as Individual;
//     const currRootNode: TreeNodeDatum = tree.state.data[0];

//     const loadAncestor = async () => {
//         setIsLoading(true);
//         const newRoot:Individual = await GenerationService.getFather(user.id);

//         if(newRoot)
//             TreeUtil.resetTree(currRootNode, newRoot, tree);

//         setIsLoading(false);
//     };
    
//     loadAncestor();
// }  

const handleLoadDescendant = (nodeDatum: TreeNodeDatum, treeRef: any, setIsLoading: Dispatch<SetStateAction<boolean>>) => {

    if(!nodeDatum.children) {
        const tree = treeRef.current as Tree;
        const user: Individual = nodeDatum.attributes!["user"] as unknown as Individual;

        const loadDescendant = async () => {
            setIsLoading(true);
            let descendants: Individual[] = await GenerationService.getDescendants(user.id);
            descendants = TreeUtil.expandUsersByWives(descendants);

            //need to find the node from the state object and modify it
            const node = TreeUtil.findNodeById(nodeDatum.__rd3t.id, tree)!;

            node.__rd3t.collapsed = false;
            const childrenNodes = TreeUtil.descendantResToTreeNodes(nodeDatum, descendants);

            node.children = childrenNodes;
            tree.setState(tree.state);

            setIsLoading(false);
        };

        loadDescendant();
    }
};


interface UserLabelProps {
    id: string;
    name: string;

    
    permAddress?: string;
    generationNo: number;

    tooltipId?: string;
    customLabel?: string;
}

const UserLabel = (props: UserLabelProps) => {
    return <>
        <div className="d-flex flex-row" style={{width: '200px', marginLeft: '0px'}}>
            <Link to={"/user-each/"+props.id} className="user-link">
                <span>{props.customLabel ?? props.name}</span>
            </Link>
        </div>
    </>
    
}

interface UserIconProps {
    gender: Gender;
    handleLoadDescendant: () => void;
}

const UserIcon = (props: UserIconProps) => {
    return <div className={"userPic "+props.gender.toLowerCase()+"UserPic"} onClick={() => {
        console.log("icon clicked");
        props.handleLoadDescendant();
    }} >

</div>
}

const HusbandWifeNode = (props: HushandWifeNodeProps) => {
    const nodeDatum = props.nodeDatum;
    const user = nodeDatum.attributes!['user'] as unknown as Individual;
    const wife = props.wife;

    const wifePosition = wife.index!;
    const hideHusbandNode = wifePosition > 0 && nodeDatum.__rd3t.collapsed;

    const husbandIconX = TreeUtil.getHusbandX(user.name);
    const husbandLabelX = husbandIconX + NODE_SIZE - 22;
    
    let wifeIconX = TreeUtil.getWifeX(husbandIconX);
    let wifeLabelX = wifeIconX + NODE_SIZE - 40;
    
    let connectingPath = TreeUtil.getCoupleConnectingPath(husbandIconX, wifeIconX);

    if(hideHusbandNode) {
        connectingPath = TreeUtil.getWifeOnlyConnectingPath(husbandIconX, wifeIconX);
        wifeIconX = husbandIconX;
        wifeLabelX = husbandLabelX;
    }

    const markHusbandAsDead = nodeDatum.attributes!['markHusbandAsDead'] === true;
    const diagonalPath = markHusbandAsDead? TreeUtil.getDiagonalPath(husbandIconX, Gender.M): '';

    // console.log(user.name +": husband", husbandIconX, husbandLabelX);
    // console.log(wife.name+ ": wife", wifeIconX, wifeLabelX);
    // console.log(connectingPath);
    return <>
            {/* Husband Node**/}
            {
                (!hideHusbandNode) &&
                <g>
                    <foreignObject x={husbandIconX} y="0" width={NODE_SIZE} height={NODE_SIZE}>
                        <UserIcon gender={user.gender} handleLoadDescendant={() => {
                            props.toggleNode();
                            handleLoadDescendant(nodeDatum, props.treeRef, props.setIsLoading);
                        }} />
                    </foreignObject>

                    <foreignObject x={husbandLabelX} y="-50" width="160" height={25}>
                        <GenTag generationNo={user.generationNo}/>
                    </foreignObject>

                    {
                        props.showBirthRank &&
                        <foreignObject x={husbandLabelX + 25} y="-50" width="160" height={25}>
                            <BirthRankTag birthRank={user.birthRank} />
                        </foreignObject>
                    }

                    
                    

                    <foreignObject x={husbandLabelX} y="-25" width="160" height={25}>
                        
                        <UserLabel id={user.id} name={user.name} permAddress={user.permAddress} generationNo={user.generationNo} />
                        
                    </foreignObject>
                    

                    {/* <path d={connectingPath} fill="transparent" strokeWidth={1} strokeDasharray={wife.isMarriageFormal? "0,0": "2,2"}/> */}

                    {
                        markHusbandAsDead &&
                        <path d={diagonalPath} fill="transparent" strokeWidth={1} />
                    }
                    
                </g>
            }
            

            <path d={connectingPath} fill="transparent" strokeWidth={1} strokeDasharray={wife.isMarriageFormal? "0,0": "2,2"}/>
            
            {/* Wife Node**/}
            <g>
                <foreignObject x={wifeIconX} y="0" width={NODE_SIZE} height={NODE_SIZE}>
                    <UserIcon gender={Gender.F} handleLoadDescendant={() => {
                        props.toggleNode();
                        handleLoadDescendant(nodeDatum, props.treeRef, props.setIsLoading);
                    }} />
                </foreignObject>
                <foreignObject x={wifeLabelX} y="-25" width="160" height={25}>
                    <UserLabel id={user.id} tooltipId={"wife_tooltip_"+wife.name} name={wife.name} permAddress={wife.permAddress} generationNo={user.generationNo} />
                </foreignObject>
            </g>
    </>
};

const SingleUserNode = (props: GenerationNodeProps) => {
    const nodeDatum = props.nodeDatum;
    const user = nodeDatum.attributes!['user'] as unknown as Individual;

    const iconX = - (NODE_SIZE / 2);
    const labelX = iconX + NODE_SIZE - (user.gender === Gender.M? 20: 15);

    const markAsDead = nodeDatum.attributes!['markAsDead'] === true;
    const diagonalPath = markAsDead? TreeUtil.getDiagonalPath(iconX, user.gender): '';

    return <>
            <foreignObject x={iconX} y="0" width={NODE_SIZE} height={NODE_SIZE}>
                <UserIcon gender={user.gender} handleLoadDescendant={() => {
                    props.toggleNode();
                    handleLoadDescendant(nodeDatum, props.treeRef, props.setIsLoading);
                }} />
            </foreignObject>

            <foreignObject x={labelX} y="-50" width="160" height={25}>
                <GenTag generationNo={user.generationNo}/>
            </foreignObject>
            
            {
                props.showBirthRank &&
                <foreignObject x={labelX + 25} y="-50" width="160" height={25}>
                    <BirthRankTag birthRank={user.birthRank} />
                </foreignObject>
            }
            

            <foreignObject x={labelX} y="-25" width="260" height={25}>
                <UserLabel id={user.id} name={user.name} permAddress={user.permAddress} generationNo={user.generationNo} />
            </foreignObject>
            
            {
                markAsDead && 
                <path d={diagonalPath} fill="transparent" strokeWidth={1} />
            }
            
    </>
}

const EachNode = (props: GenerationNodeProps) => {
    const nodeDatum = props.nodeDatum;
    const user = nodeDatum.attributes!['user'] as unknown as Individual;

    
    if(user.wives && user.wives!.length > 0) {
        return <HusbandWifeNode {...props} wife={user.wives![0]} />
    } else {
        return <SingleUserNode {...props} />
    }
    
}

export default EachNode;