import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import User from "../../models/User";
import UserService from "../../services/UserService";
import { useAlertMessage } from "../common/AlertHandler";
import { useSession } from "../common/UserSessionHandler";



interface Props {
    user?: User;
}



const DeleteModerator = (props: Props) => {
    const {setSuccesMsg} = useAlertMessage();
    const nav = useNavigate();

    const [deleting, setDeleting] = useState(false);
    const user = props.user!;
    const deleteUser = async () => {
        setDeleting(true);
        await UserService.deleteUser(user?.id!);
        nav("/moderators");
        setSuccesMsg("User deleted successfully.");
        setDeleting(false);
    };


    const onClick = () => {
        confirmDialog({
            message: "Are you sure you want to delete '"+user?.fullName+"'?",
            header: "Delete Moderator",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => {
                deleteUser();
            }
        });
    }

    const session = useSession();
    const isCurrentUser = user? session.getUser()?.id === user.id: false;
    return <Card className="mt-5">
            <Card.Header as="h4">Delete Moderator</Card.Header>
            <Card.Body>
                <Button disabled={isCurrentUser || deleting} variant="danger" size="sm" onClick={onClick}>
                    {deleting? "Deleting...": "Delete "+user?.fullName}
                    </Button>
            </Card.Body>
            <ConfirmDialog />
    </Card>
};

export default DeleteModerator;