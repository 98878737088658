import { useEffect, useState } from "react";
import { Individual } from "../models/Individual";
import GenerationService from "../services/GenerationService";

import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

import GenerationDropdown from "../components/common/GenerationDropdown";
import { CommonUtil } from "../utils/CommonUtil";

//Note: used for caching paginated individuals
const cachedIndividuals = new Map<number, Individual[]>();

const AllIndividualsPage = () => {
    const [individuals, setIndividuals] = useState<Individual[]>();
    const [generationNo, setGenerationNo] = useState<number | undefined>();

    const [totalRecords, setTotalRecords] = useState<number>(1);
    const [first, setFirst] = useState<number>(0);
    const [pageNo, setPageNo] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        cachedIndividuals.clear();
    }, [pageSize, generationNo]);

    useEffect(() => {
        const loadIndividuals = async () => {
            setLoading(true);
            const res = await GenerationService.getAllIndividualsPage(pageNo, pageSize, generationNo);
            setLoading(false);
            cachedIndividuals.set(pageNo, res);

            setIndividuals(res);
            
            let totalResultsCount = (pageNo + 1) * pageSize;

            //if we are getting same result as pagesize, then add 1 to totalResult to assume there are more
            if(res.length === pageSize)
                totalResultsCount += 1;
            
            setTotalRecords(totalResultsCount);
        };

        //check in cache first
        const cacheData = cachedIndividuals.get(pageNo);

        if(cacheData) setIndividuals(cacheData);
        else loadIndividuals();

    }, [pageNo, pageSize, generationNo]);


    const onPage = (event: DataTablePFSEvent) => {
        setFirst(event.first);
        setPageNo(event.page!);
        setPageSize(event.rows);
    }

    

    const exportPdf = async () => {
        setLoading(true);
        const data = await GenerationService.exportIndividualsList(generationNo) as Blob;
        setLoading(false);
        const url = window.URL.createObjectURL(data);

        const iframe =  document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = url;
        iframe.onload = function() {
            setTimeout(function() {
                iframe.focus();
                iframe.contentWindow!.print();
            }, 1);
        };
        

        //Note: To download as PDF
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute('download', 'users.pdf');
        // link.click();

    }


    return <div className="container">

            <div className="d-flex flex-row align-items-center mb-3">
                <GenerationDropdown generationNo={generationNo} onChange={(value) => {
                    if(value !== generationNo) {
                        setFirst(0);
                        setPageNo(0);
                        setGenerationNo(value);
                    }
                }} />

                {
                    generationNo && 
                    <Button disabled={loading} onClick={() => {
                        setFirst(0);
                        setPageNo(0);
                        setGenerationNo(undefined);
                    }} variant="outline-danger" size="sm" className="ms-2">
                                <Icon.XLg /> Clear
                    </Button>
                }
                
    
                <Button disabled={loading} onClick={exportPdf} variant="outline-dark" size="sm" 
                        style={{marginLeft: 'auto', display: 'block'}}>
                            <Icon.Printer /> Print
                </Button>
            </div>

            <DataTable className="allUsersTable" value={individuals} responsiveLayout="scroll"
                loading={loading}
                lazy paginator first={first} rows={pageSize} totalRecords={totalRecords}
                rowsPerPageOptions={[5, 10,20,50]}
                emptyMessage={generationNo? "No "+CommonUtil.ordinalSuffixOf(generationNo)+" generation users found.": "No users found."}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                onPage={onPage}
                >
                    <Column field="name" header="Name" body={(u: Individual) => {
                        return <Link to={"/user-each/"+u.id}>{u.name}</Link>
                    }}>
                    </Column>
                    <Column field="permAddress" header="Address"></Column>
                    <Column field="gender" header="Gender"></Column>
                    <Column field="generationNo" header="Generation"></Column>
            </DataTable>
    </div>
}

export default AllIndividualsPage;