import './GenerationTree.css';
import Tree from "react-d3-tree";
import { CustomNodeElementProps, RawNodeDatum } from "react-d3-tree/lib/types/common";
import { Gender, Individual } from '../../models/Individual';
import React, { MutableRefObject,  useEffect, useRef, useState } from 'react';
import { TreeUtil } from '../../utils/TreeUtil';
import LoadingSpinner from '../common/LoadingSpinner';
import { useReactToPrint } from "react-to-print";
import EachNode from './EachNode';
import { Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';




interface GenerationTreeProps {
    title: string;
    users: Individual[] | null;
    emptyMessage?: string;
}

const GenerationTree =  React.forwardRef<HTMLDivElement, GenerationTreeProps>((props: GenerationTreeProps, ref) => {
    const treeRef = useRef(null);
    const [treeData, setTreeData] = useState<RawNodeDatum | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isRootHidden, setRootHidden] = useState<boolean>(false);

    const users = props.users;

    useEffect(() => {
        if(users === null) {
            setTreeData(undefined);
            setRootHidden(false);
            setIsLoading(true);
            return;
        }

        const expandedUsers = TreeUtil.expandUsersByWives(users!);
        
        if(expandedUsers.length > 1) {
            //need to create a dummy root user, and put all users as its children
            //since d3-tree only allow one root user
            const rootUser:Individual = {
                name: 'dummy_father_random',
                about: 'dummy_about',
                permAddress: 'dummy_address',
                children: expandedUsers,
                id: new Date().getTime()+"_random_id",
                gender: Gender.M,
                generationNo: 0,
                birthRank: 1
            };

            
            setTreeData(TreeUtil.userToRawNode(rootUser, false));
            setRootHidden(true);
            setIsLoading(false);
        } else if(expandedUsers.length === 1) {
            setTreeData(TreeUtil.userToRawNode(expandedUsers[0], false));
            setRootHidden(false);
            setIsLoading(false);
        } else {
            setTreeData(undefined);
            setRootHidden(false);
            setIsLoading(false);
        }
    }, [users]);

    
    // console.log("tree data", treeData);
    
    // console.log("defaultProps", Tree.defaultProps);
    const title = props.title;
    let { innerWidth: width } = window;

    if((ref as MutableRefObject<HTMLDivElement>).current !== null) {
        const htmlDiv = (ref as MutableRefObject<HTMLDivElement>).current as HTMLDivElement;
        width = htmlDiv.offsetWidth;
    }

    return <div id="generationTree" ref={ref} className="position-relative">
            <h5 style={{textAlign: 'center', marginTop: '10px'}}>{title}</h5>
            
            {
                treeData &&
                <Tree data={treeData} 
                    orientation="vertical" 
                    ref={treeRef} 
                    rootNodeClassName={isRootHidden? "node__root_hidden": "node__root"}
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf" 
                    initialDepth={1}
                    // pathFunc="step"
                    pathFunc={TreeUtil.customPathFunction}
                    pathClassFunc={TreeUtil.getDynamicPathClass}
                    depthFactor={0}
                    nodeSize={{x: 400, y: 200}}
                    translate={{x: (width/2), y: (isRootHidden? 25: 50)}}
                    separation={{siblings: 0.8, nonSiblings: 0.8}}
                    renderCustomNodeElement={(props: CustomNodeElementProps) => {
                        const depth = props.nodeDatum.__rd3t.depth;

                        let showBirthRank = false;
                        if(isRootHidden) showBirthRank = depth > 1;
                        else showBirthRank = depth > 0;

                        return <EachNode {...props} 
                                showBirthRank={showBirthRank}
                                setIsLoading={setIsLoading}
                                treeRef={treeRef} />
                    }}
                />
            }

            {
                !isLoading && !treeData && 
                <div className="d-flex flex-column align-items-center">
                    <div className="vr" style={{margin:'0 auto'}}><span className="invisible">ver</span></div>
                    <h6 className="mt-2">
                        {props.emptyMessage? props.emptyMessage: "No users found."}
                    </h6>
                </div>
            }

            {isLoading &&
                <LoadingSpinner class='loading-spinner-md'/>
            }
            
        </div>    
});

const GenerationTreeWithNavBar = (props: GenerationTreeProps) => {
    const genTreeRef = useRef(null);
    
    const handlePrint = useReactToPrint({
        content: () => genTreeRef.current,
        documentTitle: props.title
    });

    

    return <>
        <Button variant="outline-dark" size="sm" onClick={handlePrint} style={{display: 'block', marginLeft: 'auto', marginBottom: '20px'}}>
                <Icon.Printer /> Print
        </Button>

        <GenerationTree ref={genTreeRef} title={props.title} users={props.users} emptyMessage={props.emptyMessage} />
    </>
    
};

export default GenerationTreeWithNavBar ;

