
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import * as Yup from 'yup';

import '../AddIndividualForm/AddIndividualForm.css';
import { Button, Card, } from "react-bootstrap";
import { Gender, Individual } from "../../models/Individual";
import GenerationService from "../../services/GenerationService";
import { Link, useNavigate } from "react-router-dom";
import { CommonUtil } from '../../utils/CommonUtil';
import { ArrowLeft } from "react-bootstrap-icons";
import { useAlertMessage } from '../common/AlertHandler';



interface IndividualFormValues {
    name: string;
    about: string;
    generationNo?: number;
    phone?: string;
    permAddress: string;
    
    dobDate?: string;
    dobMonth?: string;
    dobYear?: string;
    
    gender?: Gender;
    birthRank?: number;

    dodDate?: string;
    dodMonth?: string;
    dodYear?: string;

    isDead?: string;
}
  
  
const getInitialValues = (user: Individual):IndividualFormValues => {
    if(!user) 
        return {name: '', about: '', phone: '', permAddress: '', 
                        dobMonth: '', dobYear: '', dobDate: '',
                        dodMonth: '', dodYear: '', dodDate: '',
                        generationNo: 0, gender: Gender.M, birthRank: 0,
                        
                        isDead: 'false'
                    };

    const initialValues:IndividualFormValues = {
        name: user.name, about: user.about, phone: user.phone || '', permAddress: user.permAddress || '',
        generationNo: user.generationNo,
        gender: user.gender,
        birthRank: user.birthRank,
        isDead: user.isDead !== undefined? user.isDead!.toString(): 'false'
    };

    const dobValue = CommonUtil.splitDOBStr(user.dob);

    if(dobValue) {
        initialValues.dobDate = dobValue.date;
        initialValues.dobMonth = dobValue.month;
        initialValues.dobYear = dobValue.year.toString();
    } else {
        initialValues.dobDate = '';
        initialValues.dobMonth = '';
        initialValues.dobYear = '';
    }

    const dodValue = CommonUtil.splitDOBStr(user.dod);
    if(dodValue) {
      initialValues.dodDate = dodValue.date;
      initialValues.dodMonth = dodValue.month;
      initialValues.dodYear = dodValue.year.toString();
    } else {
      initialValues.dodDate = '';
      initialValues.dodMonth = '';
      initialValues.dodYear = '';
    }

    return initialValues;
}
  
const validationSchema = () => {
    return Yup.object({
        name: Yup.string().required('Enter user name'),
        about: Yup.string().required('Enter user description'),
        permAddress: Yup.string().required('Enter user address'),

        dobMonth: Yup.string().when('dobDate', {
          is: (dobDate: string) => {
            return dobDate !== undefined && dobDate !== '';
          },
          then: Yup.string().required('Enter Date of Birth'),
          otherwise: Yup.string()
        }),
        dobYear: Yup.string().when('dobMonth', {
          is: (dobMonth: string) => {
            return dobMonth !== undefined && dobMonth !== '';
          },
          then: Yup.string().required('Enter Date of Birth'),
          otherwise: Yup.string()
        }),

        birthRank: Yup.string().required('Enter Birth Rank'),
        phone: Yup.string().length(10, 'Phone must be a 10 digit number'),
        gender: Yup.string().required('Select a gender')
    });
}
  
interface TheFormProps {
}


const getMonthOptions = (monthPfx: string) => {
  return CommonUtil.MONTHS.map(m => {
    return <option value={m.value} key={monthPfx+"_"+m.name+"_opt"}>{m.name}</option>;
  });
}

const getDateOptions = (datePfx: string) => {
  return Array.from(Array(33).keys()).slice(1).map(d => {
    const value = (d < 10)? "0"+d: d;
    return <option value={value} key ={datePfx+"_"+value+"_dateopt"}>{d}</option>;
  });
}


const TheForm = (props: TheFormProps) => {
    const fc: FormikProps<IndividualFormValues> = useFormikContext();

    
    return <Form className='individualForm d-flex flex-column'>
            <div className='fieldGroups d-flex flex-row'>
              <div>
                <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage name="name" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
  
              <div>
                  <label htmlFor="permAddress" className="form-label">Permanent Address <span className="text-danger">*</span></label>
                  <Field name="permAddress" type="text" className="form-control" />
                  <ErrorMessage name="permAddress" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
              </div>
  
              <div>
                <label htmlFor="phone" className="form-label">Phone Number</label>
                <Field name="phone" type="number" className="form-control" />
                <ErrorMessage name="phone" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
            </div>
            
            <div className='fieldGroups d-flex flex-row'>
                <div style={{maxWidth: '50%'}}>
                  <label htmlFor="about" className="form-label">Description <span className="text-danger">*</span></label>
                  <Field name="about" as="textarea" type="text" rows="5" className="form-control" />
                  <ErrorMessage name="about" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
                </div>
            </div>

            <div className='fieldGroups d-flex flex-row'>
                <div className="dateOfInput">
                  <label className="form-label">Date of Birth (B.S)</label>
  
                  <div className="d-flex flex-row dobFields">
                    <div className="d-flex flex-column">
                      <label htmlFor="dobDate" className="form-label">Date</label>
                      <Field name="dobDate" as="select" className="form-control" >
                        <option value="">Select Date</option>
                        {getDateOptions("dob")}
                      </Field>
                    </div>

                    <div className="d-flex flex-column">
                      <label htmlFor="dobMonth" className="form-label">Month</label>
                
                      <Field name="dobMonth" as="select" className="form-control" >
                        <option value="">Select Month</option>
                        {getMonthOptions("dob")}
                      </Field>
                    </div>
                    
                    <div className="d-flex flex-column">
                      <label htmlFor="dobYear" className="form-label">Year</label>
                      <Field name="dobYear" type="number" className="form-control" placeholder="E.g: 2047" />
                    </div>
                  </div>

                  <ErrorMessage name="dobDate" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>

                  <ErrorMessage name="dobMonth" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
                  
                  <ErrorMessage name="dobYear" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
                </div>
                
                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="isDead" className="form-label">Is Dead?</label>
                  <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="isYesDeadInput">Yes</label> <Field type="radio" id="isYesDeadInput" name="isDead" value="true" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="isNoDeadInput">No</label> <Field type="radio" id="isNoDeadInput" name="isDead" value="false" /></div>
                  </div>

                  
                </div>

                <div className="dateOfInput">
                  <label className="form-label">Date of Demise (B.S)</label>
  
                  <div className="d-flex flex-row dobFields">
                    <div className="d-flex flex-column">
                      <label htmlFor="dodDate" className="form-label">Date</label>
                      <Field name="dodDate" as="select" className="form-control"  disabled={fc.values.isDead !== 'true'}>
                        <option value="">Select Date</option>
                        {getDateOptions("dod")}
                      </Field>
                    </div>

                    <div className="d-flex flex-column">
                      <label htmlFor="dodMonth" className="form-label">Month</label>
                
                      <Field name="dodMonth" as="select" className="form-control" disabled={fc.values.isDead !== 'true'}>
                        <option value="">Select Month</option>
                        {getMonthOptions("dod")}
                      </Field>
                    </div>
                    
                    <div className="d-flex flex-column">
                      <label htmlFor="dodYear" className="form-label">Year</label>
                      <Field name="dodYear" type="number" className="form-control" placeholder="E.g: 2047" disabled={fc.values.isDead !== 'true'} />
                    </div>
                  </div>
                  
                  <ErrorMessage name="dodYear" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
                </div>
            </div>
  
            <div className='fieldGroups d-flex flex-row'>
              <div>
                <label htmlFor="gender" className="form-label">Gender <span className="text-danger">*</span></label>
  
                <Field name="gender" as="select" className="form-control" >
                      <option value="">Select Gender</option>
                        <option value={Gender.M}>{CommonUtil.toGenderStr(Gender.M)}</option>
                        <option value={Gender.F}>{CommonUtil.toGenderStr(Gender.F)}</option>
                        <option value={Gender.O}>{CommonUtil.toGenderStr(Gender.O)}</option>
                  </Field>
                <ErrorMessage name="gender" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
  
              <div>
                  <label htmlFor="birthRank" className="form-label">Birth Rank<span className="text-danger">*</span></label>
  
                  <Field name="birthRank" type="number" className="form-control" />
                  <ErrorMessage name="birthRank" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
              </div>
              <div className="flex-grow-1"></div>
            </div>
  
  
            <hr />
            <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={fc.isSubmitting}>
                {fc.isSubmitting? "Updating...": "Update"}
            </Button>
          </Form>
}
  
  
const formValuesToIndividual = (values: IndividualFormValues): Individual => {
    const individual: Individual =  {id: '', generationNo: 0, 
                                    name: values.name, about: values.about, permAddress: values.permAddress, phone: values.phone,
                                    birthRank: values.birthRank!, gender: values.gender!,
                                    isDead: values.isDead === 'true'
                                    };

    if(values.dobMonth && values.dobYear && values.dobDate) 
        individual.dob = CommonUtil.getDOBStr(values.dobDate, values.dobMonth, values.dobYear);


    if(values.dodMonth && values.dodYear && values.dodDate) 
        individual.dod = CommonUtil.getDOBStr(values.dodDate, values.dodMonth, values.dodYear);

    return individual;
};

interface Props {
    individual?: Individual;
}

const EditIndividualForm = (props: Props) => {
    const individual = props.individual;
    const nav = useNavigate(); 
    const {setSuccesMsg} = useAlertMessage();
    
    const initialValues:IndividualFormValues = getInitialValues(individual!);

    const onFormSubmit = ((values: IndividualFormValues, helper: FormikHelpers<IndividualFormValues>) => {

      const toUpdateIndividual = formValuesToIndividual(values);

      const submitData = async (userId: string, updateReq: Individual) => {
        await GenerationService.updateIndividual(userId, updateReq).then(res => {
          helper.setSubmitting(false);
          setSuccesMsg("User updated successfully.");

          nav("/user-each/"+userId);
        }).catch(err => {
          helper.setSubmitting(false);
        });
        
      };

      submitData(props.individual!.id, toUpdateIndividual);
      
    });

    const backLinkUrl = individual? "/user-each/"+individual.id: "#";
    return <>
        <Link className="btn btn-secondary btn-sm mb-3" to={backLinkUrl}><ArrowLeft /> Go Back</Link>
        <Card>
            <Card.Header as="h4">{individual? <Link to={backLinkUrl}>{decodeURI(individual.name)}</Link>: ""} / Edit</Card.Header>
            <Card.Body>
              <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize
                onSubmit={onFormSubmit}>
                  <TheForm />
              </Formik>
            </Card.Body>
        </Card>
    </>
};

export default EditIndividualForm;