import { useMemo } from 'react'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAlertMessage } from './AlertHandler';
import Message, { MessageType } from '../../models/Message';
import HttpService from '../../services/HttpService';
import { useNavigate } from 'react-router-dom';
import SessionService from '../../services/SessionService';
import { useSession } from './UserSessionHandler';


const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = SessionService.getToken();
    if(token != null) {
        config.headers!['Authorization'] = "Bearer "+token;
    }

    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
}

const onResponseError = (error: AxiosError, setAlertMessage: (value: Message) => void, 
                        on401: () => void): Promise<AxiosError> => {
    if(error.response && error.response!.status === 401) {
        on401();
    }

    if(error.response && error.response!.data) {
        const msg:Message = error.response!.data as Message;
        setAlertMessage(msg);
    } else {
        setAlertMessage({message: "Some unknown error occurred", type: MessageType.ERROR});
    }
        
    return Promise.reject(error);
}

interface Props {
    children: any
}

/**
 * Since, we cannot use hooks inside a Typescript class, we use this component to setup the hook for setting alert message
 * 
 * Solution suggested here: https://stackoverflow.com/questions/64296505/usecontext-inside-axios-interceptor
 */
const WithAxios = ({ children }: Props) => {
    const { setAlertMessage } = useAlertMessage();
    const nav = useNavigate();
    const session = useSession();
    const httpService = HttpService;

    useMemo(() => {
        httpService.getResponseInterceptor().use(onResponse, async (error) => {
            onResponseError(error, setAlertMessage, () => {
                session.removeSessionToken();
                nav("/");
            });
        });

        httpService.getRequestInterceptor().use(onRequest, onRequestError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [httpService, setAlertMessage])

    return children;
}

export default WithAxios;