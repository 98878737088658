import { Individual } from "../../models/Individual";
import Table from 'react-bootstrap/Table';
import { Button} from "react-bootstrap";
import { CommonUtil } from '../../utils/CommonUtil';
import { PencilFill } from "react-bootstrap-icons";
import GenerationService from "../../services/GenerationService";
import { useAlertMessage } from "../common/AlertHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface Props {
    individual: Individual;
    onEdit: () => void;
    fatherId?: string;
}

const ReviewIndividualForm = (props: Props) => {
    const individual = props.individual;
    const [submitting, setSubmitting] = useState(false);
    const nav = useNavigate(); 
    const { setSuccesMsg } = useAlertMessage();
    const fatherId = props.fatherId;
    
    const onSubmit = () => {
        GenerationService.addIndividual(individual).then(addedIndividual => {
            setSubmitting(false);
            setSuccesMsg("User added successfully.");

            if(fatherId) nav("/user-each/"+fatherId);
            else nav("/user-each/"+addedIndividual.id);
        }).catch(err => {
            setSubmitting(false);
        });
    };

    return <div>
                <Table striped bordered hover>
                    <tbody>
                        <tr><td>Name:</td><td>{individual.name}</td></tr>
                        <tr><td>Description:</td><td>{individual.about? individual.about: '-'}</td></tr>
                        <tr><td>Permanent Address:</td><td>{individual.permAddress? individual.permAddress: '-'}</td></tr>
                        <tr><td>Phone:</td><td>{individual.phone ? individual.phone: '-'}</td></tr>
                        <tr><td>Gender:</td><td>{CommonUtil.toGenderStr(individual.gender)}</td></tr>
                        <tr><td>Birth Rank:</td><td>{individual.birthRank}</td></tr>
                        <tr><td>Date of Birth (B.S):</td><td>{individual.dob? CommonUtil.getDOBReadableStr(individual.dob!): '-'}</td></tr>
                        <tr><td>Date of Demise (B.S):</td><td>{individual.dod? CommonUtil.getDOBReadableStr(individual.dod!): '-'}</td></tr>
                        <tr><td>Is Dead?</td><td>{individual.isDead === true? 'Yes': 'No'}</td></tr>
                    </tbody>
                </Table>

                <hr />
                <div>
                    <Button disabled={submitting} variant="secondary" size="sm" style={{width: '20%', marginRight: '15px'}} 
                            onClick={props.onEdit}>
                        <PencilFill /> Edit
                    </Button>
                    <Button disabled={submitting} variant="primary" size="sm" style={{width: '20%'}}
                            onClick={onSubmit}>
                        { submitting? "Submitting...": "Submit" }
                    </Button>
                </div>
    </div>
};

export default ReviewIndividualForm;