import { AutoComplete } from 'primereact/autocomplete';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Individual } from '../../models/Individual';
import GenerationService from '../../services/GenerationService';
import { GenTag } from './IndividualTag';

const UserSearchBar = () => {
    const [users, setUsers] = useState<Individual[]>([]);
    const [selectedUser, setSelectedUser] = useState<Individual | undefined>();

    const nav = useNavigate();
    const searchUser = async (event: {query: string}) => {
        if(event.query) {
            const res = await GenerationService.searchIndividuals(event.query);
            setUsers(res);
        }
        
    }

    const itemTemplate = (user: Individual) => {
        return (
            <div className="d-flex flex-column searchResItem">
                <div className='d-flex flex-row align-items-center'><span>{user.name}</span> <GenTag generationNo={user.generationNo}/></div>
                <small className='userAddress'>{user.permAddress? user.permAddress: ''}</small>
            </div>
        );
    }

    return <AutoComplete id='searchUserAutoComplete' placeholder="Search User" itemTemplate={itemTemplate}
                value={selectedUser} suggestions={users} 
                showEmptyMessage={true} minLength={2}
                completeMethod={searchUser} field="name" autoHighlight={true}
                onChange={(e) => {
                    setSelectedUser(e.value);
                }} 
                onSelect={(e) => {
                        console.log(e);
                        const u = e.value as Individual;
                        nav("/user-each/"+u.id);
                    }} />
       
};

export default UserSearchBar;