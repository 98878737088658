import { useEffect, useState } from "react";
import { Individual } from "../../models/Individual";
import GenerationService from "../../services/GenerationService";
import LoadingSpinner from "../common/LoadingSpinner";
import EditGenerationForm from "./EditGenerationForm";
import EditIndividualForm from "./EditIndividualForm";
import ParentSettingForm from "./ParentSettingForm";

interface Props {
    userId: string;
}

const IndividualSetting = (props: Props) => {
    const [individual, setIndividual] = useState<Individual | undefined>();
    
    useEffect(() => {
        const loadIndividual = async () => {
          const res:Individual = await GenerationService.getIndividual(props.userId);
          setIndividual(res);
        }
  
        loadIndividual();
      }, [props.userId]);

    if(!individual) return <div className="container position-relative"><LoadingSpinner class="loading-spinner-md"/></div>;

    return <div className="container">
        <EditIndividualForm individual={individual} />
        <EditGenerationForm individual={individual} />
        <ParentSettingForm individual={individual} />
    </div>
}

export default IndividualSetting;