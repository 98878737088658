import User from "../models/User";

class SesssionService {

    saveSession(token: string) {
        localStorage.setItem("g-jwt", token);
    }

    getToken():string | null {
        const token = localStorage.getItem("g-jwt");
        if(token == null)
            return null;

        if(this._hasTokenExpired(token)) 
            return null;

        return token;
    }

    clearToken() {
        localStorage.removeItem("g-jwt");
    }

    getUser(): User | null {
        const token = this.getToken();
        if(token == null)
            return null;

        const tokenData = JSON.parse(window.atob(token.split(".")[1]));
        const user:User = JSON.parse(tokenData["ga_user"]);
        return user;
    }

    _hasTokenExpired(token: string): boolean {
        const tokenData = JSON.parse(window.atob(token.split(".")[1]));
        const tokenExpiry = tokenData['exp'] * 1000;

        return new Date().getTime() > tokenExpiry;
    }

    isLoggedIn():boolean {
        return this.getToken() != null;
    }

}

export default new SesssionService();




