import { useEffect, useState } from "react";
import { Individual } from "../models/Individual";
import GenerationService from "../services/GenerationService";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import { useAlertMessage } from "../components/common/AlertHandler";

const AllUnsyncedIndividualsPage = () => {
    const [individuals, setIndividuals] = useState<Individual[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const {setAlertMessage} = useAlertMessage();

    const loadIndividuals = async () => {
        setLoading(true);
        const res = await GenerationService.getAllUnSyncedIndividuals();
        setLoading(false);
        setIndividuals(res);
    };

    useEffect(() => {
        loadIndividuals();
    }, []);

    const uploadUsers = async () => {
        setLoading(true);

        try {
            const res = await GenerationService.uploadUnSyncedIndividuals();
            setAlertMessage(res);
            loadIndividuals();
        } catch(error: any) {
            setLoading(false);
        }
        
    };

    return <div className="container">

            <div className="d-flex flex-row align-items-center mb-3">
    
                <Button disabled={loading || individuals?.length === 0} onClick={uploadUsers} variant="outline-dark" size="sm" 
                        style={{marginLeft: 'auto', display: 'block'}}>
                            <Icon.Cloud /> Upload
                </Button>
            </div>

            <DataTable className="allUsersTable" value={individuals} responsiveLayout="scroll"
                loading={loading}
                paginator
                rows={10}
                rowsPerPageOptions={[5,10,20,50]}
                emptyMessage="No users found."
                paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                >
                    <Column field="name" header="Name" body={(u: Individual) => {
                        return <Link to={"/user-each/"+u.id}>{u.name}</Link>
                    }}>
                    </Column>
                    <Column field="permAddress" header="Address"></Column>
                    <Column field="gender" header="Gender"></Column>
                    <Column field="generationNo" header="Generation"></Column>
            </DataTable>
    </div>
}

export default AllUnsyncedIndividualsPage;