import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import User, { UserRole } from "../../models/User";
import UserService from "../../services/UserService";
import SessionService from "../../services/SessionService";



const AllModeratorsPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const nav = useNavigate();
    useEffect(() => {
        const loadUsers = async () => {
            const res = await UserService.getAllUsers();
            setUsers(res);
        }
        loadUsers();
    }, []);

    const currentUser = SessionService.getUser();

    if(currentUser === null) return <div>404</div>

    const header = <div className="d-flex flex-row justify-content-between">
                        <h4>All Moderators</h4> <Button onClick={() => nav("/moderators/add")}><PlusSquare /> Add Moderator</Button>
                    </div>;

    return <div className="container">
        <DataTable loading={users.length === 0} header={header} 
        value={users} responsiveLayout="scroll">
            <Column field="fullName" header="Name"></Column>
            <Column field="phoneNumber" header="Phone Number"></Column>
            <Column field="role" header="User Role"></Column>
            <Column field="status" header="User Status"></Column>
            <Column header="Action" body={(u: User) => {
                if(currentUser.role !== UserRole.ADMIN)
                    return <div>-</div>;

                return <Button variant="outline-secondary" size="sm" onClick={() => {
                    nav("/moderator/"+u.id+"/setting");
                }}><PencilSquare /> Edit</Button>
            }}></Column>
        </DataTable>
    </div>
};

export default AllModeratorsPage;