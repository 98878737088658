
export enum MessageType {
    INFO, WARN, ERROR
}

interface Message {
    message: string;
    type: MessageType;
}

export default Message;