import React from "react";
import { Routes, Route } from "react-router-dom";
import GenerationTreePage from "./pages/GenerationTreePage";
import IndividualDetailPage from "./pages/IndividualDetailPage";
import AddIndividualPage from './pages/AddIndividualPage';
import IndividualSettingPage from "./pages/IndividualSettingPage";
import RouteScrollToTop from "./RouteScrollToTop";
import AllIndividualsPage from "./pages/AllIndividualsPage";
import LoginPage from "./pages/LoginPage";
import { useSession } from "./components/common/UserSessionHandler";
import AllModeratorsPage from "./pages/moderators/AllModeratorsPage";
import AddModerator from "./pages/moderators/AddModeratorPage";
import EditModeratorPage from "./pages/moderators/EditModeratorPage";
import AllUnsyncedIndividualsPage from "./pages/AllUnsyncedIndividualsPage";

const AppRoutes = () => {

    const session = useSession();
    return <RouteScrollToTop>
        <Routes>
            <Route path="/" element={session.isLoggedIn()? <GenerationTreePage />: <LoginPage />} />
            <Route path="/tree" element={<GenerationTreePage />} />
            <Route path="/users/list" element={<AllIndividualsPage />} />
            <Route path="/users/list/unsynced" element={<AllUnsyncedIndividualsPage />} />
            <Route path="/user-each/add/:id/wife" element={<AddIndividualPage />} />
            <Route path="/user-each/add" element={<AddIndividualPage />} />
            <Route path="/user-each/:id" element={<IndividualDetailPage />} />
            <Route path="/user-each/wife/setting" element={<IndividualSettingPage />} />
            <Route path="/user-each/:id/setting" element={<IndividualSettingPage />} />

            <Route path="/moderators" element={<AllModeratorsPage />} />
            <Route path="/moderators/add" element={<AddModerator />} />
            <Route path="/moderator/:id/setting" element={<EditModeratorPage />} />
        </Routes>
    </RouteScrollToTop>
};

export default AppRoutes;

