


const Footer = () => {
    const today = new Date();
    return <div id="footer" className="d-flex flex-column">
        <span>&#169; Copyright {process.env.REACT_APP_PROJECT_NAME} <small>v{process.env.REACT_APP_VERSION}</small> - {today.getFullYear()}</span>
        <small className="mt-1">Developed by - <a href="https://www.linkedin.com/in/abiraj-s-tamang-241b2b38/" rel="noreferrer" target="_blank">Abiraj S. Tamang</a></small>
    </div>
};

export default Footer;