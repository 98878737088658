import { useParams, useLocation } from "react-router-dom";
import EditWifeForm from '../components/EditIndividualForm/EditWifeForm';
import { CommonUtil } from "../utils/CommonUtil";
import IndividualSetting from '../components/EditIndividualForm/IndividualSetting';


const IndividualSettingPage = () => {
    const param = useParams();
    const loc = useLocation();

    if(loc.pathname === '/user-each/wife/setting') {
        const queryParams = CommonUtil.getQueryParams(loc);

        return <EditWifeForm husbandName={queryParams.get('husbandName') ?? ''} 
                                husbandId={queryParams.get('husbandId') ?? ''} 
                                wifeId={queryParams.get('wifeId') ?? ''} />
    } else {
        const userId = param["id"] as unknown as string;
        return <IndividualSetting userId={userId} />
    }
};

export default IndividualSettingPage;