import { useEffect, useState } from "react";

import { Individual } from "../../models/Individual";
import { Button, Card, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import GenerationService from "../../services/GenerationService";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import * as Yup from 'yup';
import { CommonUtil } from "../../utils/CommonUtil";
import { Wife } from "../../models/Wife";
import { useAlertMessage } from "../common/AlertHandler";

interface ParentFormValues {
    fatherId: string;
    motherId: string;
}


const getInitialValues = (user: Individual):ParentFormValues => {
    if(!user) 
        return {fatherId: '', motherId: ''};

    const initialValues:ParentFormValues = {
        fatherId: user.fatherId ?? '',
        motherId: user.motherId ?? '',
    };

    return initialValues;
}

const validationSchema = () => {
    return Yup.object({
        fatherId: Yup.string().required('Select Father'),
        motherId: Yup.string().required('Select Mother'),
    });
}

interface TheFormProps {
    currIndividual?: Individual;
    fathers: Individual[];
}



const TheForm = (props: TheFormProps) => {
    const formikContext: FormikProps<ParentFormValues> = useFormikContext();
    const individual = props.currIndividual;
    const [mothers, setMothers] = useState<Wife[] | undefined>(undefined);

    const fatherOptions = props.fathers.map(u => {
        return <option value={u.id} key={u.id+"_father_select"}>{u.name} ({CommonUtil.ordinalSuffixOf(u.generationNo)} generation)</option>;
    });

    let motherOptions = mothers !== undefined? mothers.map(w => {
        return <option value={w.id} key={w.id+"_wife_select"}>{w.name}</option>;
    }): null;

    const selectedFatherId = formikContext.values.fatherId;

    useEffect(() => {
        if(selectedFatherId && props.fathers.length > 0) {
            const selectedFather:Individual = props.fathers.filter(u => u.id === selectedFatherId)[0];

            if(selectedFather && selectedFather.wives) {
                selectedFather.wives.sort((w1, w2) => w1.rank - w2.rank);
                setMothers(selectedFather.wives);
            } else {
                setMothers([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fathers, selectedFatherId, individual]);

    
    useEffect(() => {
        const selectedMotherId = formikContext.values.motherId;
        if(mothers !== undefined && mothers.filter(m => m.id === selectedMotherId).length === 0)
            formikContext.setFieldValue('motherId', '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mothers,]);

    return <Form className='individualForm d-flex flex-column'>
        <div className='fieldGroups d-flex flex-row'>
            <div>
                <label htmlFor="fatherId" className="form-label">Father</label>

                <Field name="fatherId" as="select" className="form-control">
                    <option value="">Select Father</option>
                    {fatherOptions}
                </Field>
            </div>

            <div>
                <label htmlFor="motherId" className="form-label">Mother</label>

                <Field name="motherId" as="select" className="form-control" >
                  <option value="">Select Mother</option>
                  {motherOptions}
                </Field>

                <ErrorMessage name="motherId" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
            </div>

            <div className="invisible"></div>
        </div>

        <hr />
        <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={formikContext.isSubmitting}>
            {formikContext.isSubmitting? "Updating...": "Update Parents"}
        </Button>
    </Form>
}


interface Props {
    individual?: Individual;
}

const ParentSettingForm = (props:  Props) => {
    const nav = useNavigate(); 
    const individual = props.individual;

    const [fathers, setFathers] = useState<Individual[]>([]);

    const initialValues:ParentFormValues = getInitialValues(individual!);
    const {setSuccesMsg} = useAlertMessage();

    useEffect(() => {
        const loadIndividuals = async (genNo: number) => {
        //   const res: Individual[] = await GenerationService.getAllIndividuals();
          const res: Individual[] = await GenerationService.getIndividuals(genNo);
          setFathers(res);
        }
        
        if(individual)
            loadIndividuals(individual!.generationNo - 1);
    }, [individual]);

    const onFormSubmit = ((values: ParentFormValues, helper: FormikHelpers<ParentFormValues>) => {
        //alert(JSON.stringify(values));

        const submitData = async (individual: Individual, fatherId: string, motherId: string) => {
            await GenerationService.updateParents({individualId: individual.id, fatherId: fatherId, motherId: motherId});
            helper.setSubmitting(false);
            setSuccesMsg("User parents updated successfully.");

            nav("/user-each/"+individual.id);
        }

        submitData(individual!, values.fatherId, values.motherId);

    });

    return <div className="container my-4">

            <Card>
            <Card.Header as="h4">{individual? <Link to={"/user-each/"+individual.id}>{decodeURI(individual.name)}</Link>: ""} Parents</Card.Header>
            <Card.Body>
            <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize
                onSubmit={onFormSubmit}>
                  <TheForm currIndividual={individual!} fathers={fathers} />
              </Formik>
            </Card.Body>
        </Card>
    </div>
};

export default ParentSettingForm;