import React from "react";
import "./common.css";

interface LoadingSpinnerProps {
  class?: string; 
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <div className="spinner-container">
      <div className={"loading-spinner "+(props.class || '')}>
      </div>
    </div>
  );
};

export default LoadingSpinner;