import { ChangePasswordRequest } from "../models/ChangePasswordReq";
import LoginRequest from "../models/LoginRequest";
import LoginSession from "../models/LoginSession";
import Message from "../models/Message";
import User from "../models/User";
import HttpService from "./HttpService";


class UserService {
    _baseUrl: string = "/user";

    async login(phoneNumber: number, password: string): Promise<LoginSession> {
        const loginRequest:LoginRequest = {phoneNumber: phoneNumber, password: window.btoa(password)};
        return await HttpService.post(this._baseUrl + "/login", loginRequest);
    }

    async getUser(id: string): Promise<User> {
        return await HttpService.get(this._baseUrl + "/"+id);
    }

    async getAllUsers(): Promise<User[]> {
        return await HttpService.get(this._baseUrl + "/list");
    }


    async register(user: User): Promise<User> {
        return await HttpService.post(this._baseUrl + "/register", user);
    }

    async updateUser(user: User): Promise<User> {
        return await HttpService.post(this._baseUrl + "/"+user.id+"/update", user);
    }

    async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<Message> {
        return await HttpService.post(this._baseUrl + "/changePassword", changePasswordRequest);
    }

    async deleteUser(userId: string): Promise<Message> {
        return await HttpService.delete(this._baseUrl + "/"+userId);
    }


}

export default new UserService();