import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChangeModeratorPwd from "../../components/EditModeratorForm/ChangeModeratorPwd";
import DeleteModerator from "../../components/EditModeratorForm/DeleteModerator";
import EditModeratorForm from "../../components/EditModeratorForm/EditModeratorForm";
import User from "../../models/User";
import UserService from "../../services/UserService";

import { Button } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import './moderator.css';
import LoadingSpinner from "../../components/common/LoadingSpinner";

const EditModeratorPage = () => {
    const nav = useNavigate();
    const param = useParams();
    const id = param.id!;
    const [user, setUser] = useState<User | undefined>();

    useEffect(() => {
        const loadUser = async () => {
            const res = await UserService.getUser(id);
            setUser(res);
        };

        loadUser();
    }, [id]);

    return <div className="container">
            <Button variant="secondary" size="sm" type="button" style={{width: 'fit-content', marginBottom: '20px',}} 
                         onClick={() => {
                            nav("/moderators")
                         }}>
                    <ArrowLeft /> Go Back
            </Button>

            {
                !user? <div className="container position-relative"><LoadingSpinner class="loading-spinner-md"/></div>:
                        <div className="d-flex flex-row">
                        <EditModeratorForm user={user} />

                        <div className="flex-grow-1 ms-3">
                            <ChangeModeratorPwd user={user} />
                            <DeleteModerator user={user} />
                        </div>
                    </div>
            }

            
            
        </div>
};


export default EditModeratorPage;