import { useRef, useEffect } from "react";

import Message, { MessageType } from "../../models/Message";
import { Toast } from 'primereact/toast';
import { ToastSeverityType } from "primereact/toast";




interface ToastProps {
    toast: Toast;
    message: Message;
}

const showToast = (props: ToastProps) => {
    let severity:ToastSeverityType = "info", title = "Info";

    const msg = props.message;
    if(msg.type.toString() === "INFO" || msg.type === MessageType.INFO) {
        severity = "success";
        title = "Success";
    } else if(msg.type.toString() === "WARN" || msg.type === MessageType.WARN) {
        severity = "warn";
        title = "Warning";
    } else if(msg.type.toString() === "ERROR" || msg.type === MessageType.ERROR) {
        severity = "error";
        title = "Error";
    }

    props.toast.show({life: 5000, severity: severity, summary: title, detail: msg.message});
}

interface Props {
    message: Message;
}

const AlertNotification = (props: Props) => {
    const toast = useRef<Toast>(null);

    const message = props.message;
    useEffect(() => {
        if(message) {
            showToast({toast: toast.current!, message: message});
        }
    }, [message]);

    return <>
        <Toast ref={toast} />
    </>
};

export default AlertNotification;