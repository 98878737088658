import { useEffect, useState} from "react";
import GenerationService from "../../services/GenerationService";
import { Individual } from '../../models/Individual';
import LoadingSpinner from '../common/LoadingSpinner';
import { Card, Button, Spinner} from "react-bootstrap";

import Table from 'react-bootstrap/Table';
import * as Icon from 'react-bootstrap-icons';

import './IndividualDetail.css'
import GenerationTree from '../GenerationTree/GenerationTree';
import { Link, useNavigate } from "react-router-dom";
import { CommonUtil } from '../../utils/CommonUtil';
import { DataTable } from "primereact/datatable";
import { Column} from "primereact/column";
import { Wife } from '../../models/Wife';
import { DataTableRowEditCompleteParams } from "primereact/datatable";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { useAlertMessage } from '../common/AlertHandler';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { GenTag } from "../common/IndividualTag";

interface UserProps {
    user: Individual;
}

const DetailBody = (props: UserProps) => {
    const navigate = useNavigate();
    const {setSuccesMsg} = useAlertMessage();
    
    const [father, setFather] = useState<Individual | undefined>();
    const [motherName, setMotherName] = useState<string>('');

    const [deleting, setDeleting] = useState<boolean>(false);
    const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

    const user = props.user;

    
    
    const onDelete = () => {
        const userId = props.user.id;

        
        const deleteUser = async (userId: string) => {
            try {
                setDeleting(true);
                await GenerationService.deleteIndividual(userId);
                setDeleting(false);
                setSuccesMsg("User Deleted Successfully");

                if(props.user.fatherId) navigate("/user-each/"+props.user.fatherId);
                else navigate("/");
            } catch(error: any) {
                setDeleting(false);
            }
            
        }

        confirmDialog({
            message: "Are you sure you want to delete '"+user.name+"'?",
            header: "Delete User",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => {
                deleteUser(userId);
            }
        });

        
    }

    useEffect(() => {
        const fatherId = props.user.fatherId;

        const loadParents = async (fatherId: string) => {
            const father:Individual = await GenerationService.getIndividual(fatherId, "id", "name", "wives");
            setFather(father);

            const matchedWife = father.wives? father.wives!.filter(w => w.id === props.user.motherId): [] as Wife[];
            const mName = (matchedWife && matchedWife.length === 1)? matchedWife[0].name: '';
            setMotherName(mName);
        };

        if(fatherId) {
            loadParents(fatherId);
        } else {
            setFather(undefined);
            setMotherName('');
        }
    }, [props.user.fatherId, props.user.motherId]);

    const exportPdf = async () => {
        setLoadingPDF(true);
        const data = await GenerationService.exportIndividualsDetail(user.id) as Blob;
        setLoadingPDF(false);
        
        const url = window.URL.createObjectURL(data);

        const iframe =  document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = url;
        iframe.onload = function() {
            setTimeout(function() {
                iframe.focus();
                iframe.contentWindow!.print();
            }, 1);
        };
        

        //Note: To download as PDF
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute('download', 'users.pdf');
        // link.click();

    }

    return (
        <>
            <div className="mb-4 d-flex flex-row" id="detailBody">
                <ConfirmDialog />
                <Card className="p-4" style={{minWidth: '25%', background: '#f2f2f2b0'}}>
                    <div className="d-flex flex-column">
                        <div className={"userPic "+user.gender.toLowerCase()+"UserPic"}></div>
                        <div className="ml-auto mt-4">
                            <GenTag generationNo={user.generationNo}/>
                        </div>
                        <h4 className="">{user.name}</h4>

                        <Link to={"/user-each/"+user.id+"/setting"} ><Button className="mb-4 mt-2" style={{width: '100%'}} variant="dark" size="sm" onClick={() => {}}><PencilSquare /> Edit User</Button></Link>
                    </div>
                </Card>

                <Card className="flex-grow-1" style={{marginLeft: '20px'}}>
                    <Card.Body>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="d-flex flex-row align-items-center">
                                            <h4>About</h4>
                                            <Button disabled={loadingPDF} onClick={exportPdf} variant="outline-dark" size="sm" 
                                                    style={{marginRight: 'auto', marginLeft: '10px', display: 'block'}}>
                                                        <Icon.Printer /> Print
                                            </Button>
                                            <Button className="mt-auto" disabled={deleting} style={{width: 'fit-content', marginLeft: 'auto'}} 
                                                    variant="danger" size="sm" onClick={onDelete}>
                                                    {deleting? <Spinner animation="border" variant="light" size="sm" />: <Trash />}
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr><td>Description:</td><td>{user.about? user.about: '-'}</td></tr>
                                <tr><td>Permanent Address:</td><td>{user.permAddress? user.permAddress: '-'}</td></tr>
                                <tr><td>Phone:</td><td>{user.phone ? user.phone: '-'}</td></tr>
                                <tr><td>Gender:</td><td>{CommonUtil.toGenderStr(user.gender)}</td></tr>
                                <tr><td>Date of Birth (B.S):</td><td>{user.dob? CommonUtil.getDOBReadableStr(user.dob!): '-'}</td></tr>
                                <tr><td>Date of Demise (B.S):</td><td>{user.dod? CommonUtil.getDOBReadableStr(user.dod!): '-'}</td></tr>
                                <tr><td>Is Dead?</td><td>{user.isDead === true? 'Yes': 'No'}</td></tr>
                                <tr><td colSpan={2}></td></tr>
                                <tr><td colSpan={2}><h4>{user.name} Parents</h4></td></tr>
                                <tr><td>Father Name:</td>
                                        <td>
                                        {
                                            father?
                                            <Link to={"/user-each/"+father.id}>{father.name}</Link>:
                                            <span>{'-'}</span>
                                        }
                                        </td>
                                </tr>
                                <tr><td>Mother Name:</td><td>{motherName === ''? '-': motherName}</td></tr>
                            </tbody>
                        </Table>
                        
                    </Card.Body>
                        
                </Card>
            </div>
        </>
  )
};



const ChildrenTable = (props: UserProps) => {
    const user = props.user;
    const children = user.children;

    if(children) children.sort((u1, u2) => u1.birthRank - u2.birthRank);

    return <Card style={{ width: '100%', height: 'fit-content', marginRight: '10px' }} className='mt-4'>
            <Card.Header as="div">{user.name} Children</Card.Header>

            <Card.Body>
                <DataTable value={children!} size="small" stripedRows emptyMessage="No children found.">
                    <Column style={{ width: '10%' }}  field="birthRank" header=""></Column>
                    <Column field="name" header="Name" body={(u: Individual) => {
                        return <Link to={"/user-each/"+u.id}>{u.name}</Link>
                    }}></Column>
                    <Column field="gender" header="Gender" body={(u: Individual) => {
                        return CommonUtil.toGenderStr(u.gender);
                    }}></Column>
                    <Column field="generationNo" header="Generation" body={(u: Individual) => {
                        return CommonUtil.ordinalSuffixOf(u.generationNo);
                    }}></Column>
                </DataTable>
            </Card.Body>

            <Card.Footer>
                <footer className="userDataTableFooter">
                    <Button variant="link" size="sm" style={{marginLeft: '10px', 'textDecoration': 'none'}}>
                        <Link style={{'textDecoration': 'none'}} to={"/user-each/add?fatherId="+user.id+"&gender=M"}>+ Add Son</Link>
                    </Button>

                    <Button variant="link" size="sm" style={{marginLeft: '10px', 'textDecoration': 'none'}}>
                        <Link style={{'textDecoration': 'none'}} to={"/user-each/add?fatherId="+user.id+"&gender=F"}>+ Add Daughter</Link>
                    </Button>

                    <Button variant="link" size="sm" style={{marginLeft: '10px', 'textDecoration': 'none'}}>
                        <Link style={{'textDecoration': 'none'}} to={"/user-each/add?fatherId="+user.id+"&gender=O"}>+ Add Other</Link>
                    </Button>
                </footer>
            </Card.Footer>
        </Card>
}


const WifeTable = (props: UserProps) => {
    const user = props.user;
    const wives = user.wives;
    const wivesCount = wives ? wives.length: 0;
    const wifeStr = wivesCount > 1? "Wives": "Wife";

    if(wives) wives.sort((w1, w2) => w1.rank - w2.rank);

    return <Card style={{ width: '100%', height: 'fit-content' }} className='mt-4'>
            <Card.Header as="div">{user.name} {wifeStr}</Card.Header>

            <Card.Body>
                <DataTable value={wives!} size="small" stripedRows emptyMessage={"No wife found."} 
                    onRowEditComplete={(e: DataTableRowEditCompleteParams) => {
                        console.log("onRowEditComplete", e);
                    }}>
                    <Column field="rank" header="" style={{ width: '10%' }}></Column>
                    <Column field="name" header="Name"></Column>
                    <Column field="isMarriageFormal" header="Formal Marriage" body={(w: Wife) => {
                        return w.isMarriageFormal? "Yes": "No";
                    }}></Column>
                    <Column body={(w: Wife) => {
                        return <Link to={"/user-each/wife/setting?husbandName="+user.name+"&husbandId="+user.id+"&wifeId="+w.id}> <PencilSquare /> </Link>
                    }}></Column>
                </DataTable>
            </Card.Body>
            <Card.Footer>
                <footer className="userDataTableFooter">
                    <Button variant="link" size="sm" style={{'textDecoration': 'none'}}>
                        <Link style={{'textDecoration': 'none'}} to={"/user-each/add/"+user.id+"/wife?husbandName="+user.name}>+ Add Wife</Link>
                    </Button>
                </footer>
            </Card.Footer>
        </Card>
}


const UserGenerationTree = (props: UserProps) => {

    return <Card style={{ width: '100%' }} className='mt-4'>
            <Card.Header as="div">{props.user.name} Generations</Card.Header>

            <Card.Body>
                <GenerationTree title="" users={[props.user]} />
            </Card.Body>
        </Card>
}


interface Props {
    userId: string;
}

const IndividualDetail = (props: Props) => {
    const userId = props.userId;

    const [user, setUser] = useState<Individual | undefined>();

    useEffect(() => {
        if(!userId) return;

        setUser(undefined);
        const fetchUser = async (userId: string) => {
            const userDetail = await GenerationService.getIndividual(userId);
            setUser(userDetail);
        };
        
        
        fetchUser(userId);
    }, [userId]);


    if(!user) return <div className="container position-relative"><LoadingSpinner class="loading-spinner-md"/></div>;

    return (
        <div className="container" id="individualDetailContainer">
            <DetailBody user={user} />
            <div className="d-flex flex-row">
                <ChildrenTable user={user} />
                <WifeTable user={user}/>
            </div>
            
            <UserGenerationTree user={user} />
    </div>)
}

export default IndividualDetail;