import { Button, Card } from "react-bootstrap";

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import { useNavigate } from 'react-router-dom';
import UserService from '../../services/UserService';
import { useAlertMessage } from '../common/AlertHandler';
import { ChangePasswordRequest } from "../../models/ChangePasswordReq";
import User from "../../models/User";

const TheForm = () => {
    const formikContext: FormikProps<PasswordFormValues> = useFormikContext();

    return <Form className='moderatorForm d-flex flex-column'>

            <div className='fieldGroups d-flex flex-column'>
                <div>
                    <label htmlFor="password" className="form-label">New Password <span className="text-danger">*</span></label>
                    <Field name="password" type="password" className="form-control" />
                    <ErrorMessage name="password" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>

                <div>
                    <label htmlFor="confirmPassword" className="form-label">Confirm Password <span className="text-danger">*</span></label>
                    <Field name="confirmPassword" type="password" className="form-control" />
                    <ErrorMessage name="confirmPassword" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>
            </div>
            
            <Button variant="primary" size="sm" type="submit" style={{width: 'fit-content'}} disabled={formikContext.isSubmitting}>
                {formikContext.isSubmitting? "Change Password...": "Change Password"}
            </Button>
    </Form>
};

interface PasswordFormValues {
    password: string;
    confirmPassword: string;
}


const validationSchema = () => {
    return Yup.object({
        password: Yup.string().required('Enter a password'),
        confirmPassword: Yup.string().test('passwords-match', 'Passwords do not match', function(value){
            return this.parent.password === value
        })
    });
}

interface Props {
    user?: User;
}

const ChangeModeratorPwd = (props: Props) => {
    const {setSuccesMsg} = useAlertMessage();
    const nav = useNavigate();
    let initialValues:PasswordFormValues = {password: '', confirmPassword: ''};

    const onFormSubmit = ((values: PasswordFormValues, helper: FormikHelpers<PasswordFormValues>) => {
        const submitData = async (changePasswordRequest: ChangePasswordRequest) => {
            await UserService.changePassword(changePasswordRequest).then(res => {
                nav("/moderators");
                setSuccesMsg("Password changed successfully.");
                helper.setSubmitting(false);
            }).catch(err => {
                helper.setSubmitting(false);
            });
            
        };
        
        submitData({userId: props.user?.id!, password: window.btoa(values.password)});
      });

    return <Card>
            <Card.Header as="h4">Change Password</Card.Header>
            <Card.Body>
                <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} 
                    onSubmit={onFormSubmit}>
                    <TheForm />
                </Formik>
            </Card.Body>
    </Card>
};

export default ChangeModeratorPwd;