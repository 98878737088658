
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { Button, Card } from 'react-bootstrap';
import User, { UserRole, UserStatus } from '../../models/User';
import { useNavigate } from 'react-router-dom';
import UserService from '../../services/UserService';
import { useAlertMessage } from '../common/AlertHandler';
import { Calendar } from 'primereact/calendar';

const TheForm = () => {
    const formikContext: FormikProps<UserFormValues> = useFormikContext();

    return <Form className='moderatorForm d-flex flex-column'>

            <div className='fieldGroups d-flex flex-column'>
                <div>
                    <label htmlFor="fullName" className="form-label">Name <span className="text-danger">*</span></label>
                    <Field name="fullName" type="text" className="form-control" />
                    <ErrorMessage name="fullName" render={
                        (error: string) => <div className='text-danger'>{error}</div>
                    }/>
                </div>

                <div>
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <Field name="phoneNumber" type="text" className="form-control" disabled={true} />
                    
                </div>

                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="role" className="form-label">User Role</label>
                  <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="moderatorRole">Moderator</label> <Field type="radio" id="moderatorRole" name="role" value="MODERATOR" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="adminRole">Admin</label> <Field type="radio" id="adminRole" name="role" value="ADMIN" /></div>
                  </div>
                </div>

                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="status" className="form-label">User Status</label>
                  <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="inactiveStatus">Inactive</label> <Field type="radio" id="inactiveStatus" name="status" value="INACTIVE" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="activeStatus">Active</label> <Field type="radio" id="activeStatus" name="status" value="ACTIVE" /></div>
                  </div>
                </div>

                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="status" className="form-label">Expiry Date</label>
                  <div className="">
                    <Calendar value={formikContext.values.expiresOn} onChange={(e) => {
                        formikContext.setFieldValue('expiresOn', e.target.value);
                    }} showTime hourFormat="12" dateFormat="M dd, yy" showIcon />
                  </div>
                </div>
                

                
            </div>
            

            <hr />

            <div className="d-flex flex-row">
                <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={formikContext.isSubmitting}>
                    {formikContext.isSubmitting? "Updating...": "Update"}
                </Button>
            </div>
    </Form>
};


interface UserFormValues {
    fullName: string;
    phoneNumber: string;
    role: string;
    status: string;
    expiresOn?: Date;
}

const getInitialValues = (user?: User):UserFormValues => {
    let initialValues:UserFormValues = {fullName: '', phoneNumber: '', role: '', status: '', expiresOn: undefined};
    
    if(user) {
        initialValues = {fullName: user.fullName, phoneNumber: user.phoneNumber!, 
                            role: user.role?.toString()!, status: user.status?.toString()!,
                            expiresOn: user.expiresOn? new Date(user.expiresOn): undefined};
    }

    return initialValues;
}

const validationSchema = () => {
    return Yup.object({
        fullName: Yup.string().required('Enter user name'),
        role: Yup.string().required('Select a role'),
        status: Yup.string().required('Select a user status'),
    });
}


interface Props {
    user?: User;
}

const EditModeratorForm = (props: Props) => {
    const {setSuccesMsg} = useAlertMessage();
    const nav = useNavigate();
    
    const initialValues = getInitialValues(props.user);

    const onFormSubmit = ((values: UserFormValues, helper: FormikHelpers<UserFormValues>) => {
        const user:User = {id: props.user!.id, fullName: values.fullName, role: values.role as UserRole, status: values.status as UserStatus};

        if(values.expiresOn) {
            user.expiresOn = values.expiresOn.getTime();
        }

        const submitData = async (u: User) => {
            await UserService.updateUser(u).then(res => {
                nav("/moderators");
                setSuccesMsg("User updated successfully.");
                helper.setSubmitting(false);
            }).catch(err => {
                helper.setSubmitting(false);
            });
            
        };
        
        submitData(user);
      });

    return <Card className="flex-grow-1" style={{maxWidth: '500px'}}>
                <Card.Header as="h4">Edit Moderator</Card.Header>

                <Card.Body>
                    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} 
                        onSubmit={onFormSubmit}>
                        <TheForm />
                    </Formik>
                </Card.Body>
        </Card>
    
};

export default EditModeratorForm;