import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom"
import UserSearchBar from "./UserSearchBar";
import { useSession } from "./UserSessionHandler";


const GenNavBar = () => {
    const session = useSession();
    const handleSelect = (eventKey: any) => alert(`selected ${eventKey}`);
    const nav = useNavigate();

    const isOffline = process.env.REACT_APP_OFFLINE === 'true';
    
    return <Navbar id="genNavBar" bg="dark" variant="dark">
            <Navbar.Brand as={Link} to="/" style={{border: "none", background: "none"}}>
                <img src="/Final HGF Logo.jpg?v=1.0" alt="HGF Logo" width="50px" />
                <span className="ms-2">{process.env.REACT_APP_PROJECT_NAME}</span>
            </Navbar.Brand>
            <Nav onSelect={handleSelect} className="ms-auto">
                {session.isLoggedIn() &&
                    <>
                        <Nav.Link as={Link} to="/users/list">All Users</Nav.Link>
                        <Nav.Link as={Link} to="/user-each/add" className="pt-1"><Button size="sm">+ Add User</Button></Nav.Link>
                    </>
                }
                </Nav>
                {session.isLoggedIn() &&
                    <>
                        <UserSearchBar />
                        
                        <Nav className="ms-2">
                            <NavDropdown align="end" title={session.getUser()?.fullName} id="nav-dropdown">
                                <NavDropdown.Item onClick={() => nav("/")}>Home</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => nav("/moderators")}>Moderators</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => {
                                    session.removeSessionToken();
                                    nav("/");
                                }}>Logout</NavDropdown.Item>

                                {
                                    isOffline &&
                                    <>
                                        <NavDropdown.Divider />
                                        <Nav.Link as={Link} to="/users/list/unsynced">Pending Users</Nav.Link>
                                    </>
                                }
                                
                            </NavDropdown>
                        </Nav>
                    </>
                }
            
    </Navbar>
}

export default GenNavBar;