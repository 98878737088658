import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';

import * as Yup from 'yup';

import '../AddIndividualForm/AddIndividualForm.css';
import { Button, Card } from "react-bootstrap";
import GenerationService from "../../services/GenerationService";
import { useNavigate, Link } from "react-router-dom";
import { Wife } from "../../models/Wife";
import { useState, useEffect } from 'react';
import { ArrowLeft } from "react-bootstrap-icons";
import { useAlertMessage } from '../common/AlertHandler';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import LoadingSpinner from '../common/LoadingSpinner';

interface WifeFormValues {
    name: string;
    permAddress?: string;
    isMarriageFormal?: string;
    rank: number;
}


const getInitialValues = (wife: Wife):WifeFormValues => {
    if(!wife)
        return {
            name: '', permAddress: '',
            isMarriageFormal: '',
            rank: 1
          };

    const initialValues:WifeFormValues = {
      name: wife.name, permAddress: wife.permAddress || '',
      isMarriageFormal: wife.isMarriageFormal.toString(),
      rank: wife.rank
    };
  
    return initialValues;
}

const validationSchema = () => {
    return Yup.object({
        name: Yup.string().required('Enter user name'),
        rank: Yup.string().required('Enter Rank No'),
        isMarriageFormal: Yup.string().required('Select Marriage Formality')
    });
}

interface TheWifeFormProps {
    husbandName: string;
    husbandId: string;
    wifeId: string;
}


const TheWifeForm = (props: TheWifeFormProps) => {
    const nav = useNavigate();
    const formikContext: FormikProps<WifeFormValues> = useFormikContext();
    const [deleting, setDeleting] = useState<boolean>(false);
    const onDelete = () => {
      
      const deleteWife = async (wifeId: string) => {
        try {
          setDeleting(true);
          await GenerationService.deleteWife(wifeId);
          setDeleting(false);
          setSuccesMsg("Wife deleted succesffully.")
          nav("/user-each/"+props.husbandId);
        } catch(err: any) {
          setDeleting(false);
        }
      }

      confirmDialog({
        message: "Are you sure you want to delete wife?",
        header: "Delete Wife",
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => {
          deleteWife(props.wifeId);
        }
    });

      

    };
    const {setSuccesMsg} = useAlertMessage();
    return <Form className='individualForm d-flex flex-column'>
            <ConfirmDialog />
            <div className='fieldGroups d-flex flex-row'>
              <div>
                <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage name="name" render={
                  (error: string) => <div className='text-danger'>{error}</div>
                }/>
              </div>
  
              <div>
                  <label htmlFor="permAddress" className="form-label">Permanent Address</label>
                  <Field name="permAddress" type="text" className="form-control" />
                  <ErrorMessage name="permAddress" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
              </div>
  
              <div>
                <label className="form-label">Is Marriage Formal? <span className="text-danger">*</span></label>
                <div className="d-flex flex-row">
                    <div className="radioInput" style={{marginRight: '10px'}}><label htmlFor="formalInput">Yes</label> <Field type="radio" id="formalInput" name="isMarriageFormal" value="true" /></div>
                    <div className="radioInput" style={{marginLeft: '10px'}}><label htmlFor="inFormalInput">No</label> <Field type="radio" id="inFormalInput" name="isMarriageFormal" value="false" /></div>
                </div>

                <ErrorMessage name="isMarriageFormal" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
              </div>
            </div>
            
            <div className='fieldGroups d-flex flex-row'>
                <div style={{marginTop: 'auto'}}>
                  <label htmlFor="rank" className="form-label">Rank No <span className="text-danger">*</span></label>
  
                  <Field name="rank" type="number" className="form-control" />
                  <ErrorMessage name="rank" render={
                    (error: string) => <div className='text-danger'>{error}</div>
                  }/>
                </div>
            </div>
  
            <hr />
            <div className="d-flex flex-row justify-content-between">
              <Button variant="primary" size="sm" type="submit" style={{width: '20%'}} disabled={formikContext.isSubmitting || deleting}>
                  {formikContext.isSubmitting? "Submitting": "Submit"}
              </Button>

              <Button variant="danger" size="sm" style={{width: '20%'}} disabled={formikContext.isSubmitting || deleting} 
                  onClick={onDelete}>{deleting? 'Deleting...': 'Delete'}</Button>
            </div>
            
          </Form>
}



const EditWifeForm = (props: TheWifeFormProps) => {
    const nav = useNavigate();
    const [wife, setWife] = useState<Wife | undefined>();
    const initialValues:WifeFormValues = getInitialValues(wife!);
    const {setSuccesMsg} = useAlertMessage();

    const onFormSubmit = ((values: WifeFormValues, helper: FormikHelpers<WifeFormValues>) => {

        const updateReq:Wife = {id: props.wifeId, 
                        name: values.name, rank: values.rank, 
                        permAddress: values.permAddress, 
                        isMarriageFormal: values.isMarriageFormal === 'true'}

        const submitData = async (updateReq: Wife) => {
          try {
            await GenerationService.updateWife(props.wifeId, updateReq);
            helper.setSubmitting(false);
            setSuccesMsg("Wife updated successfully.");

            nav("/user-each/"+props.husbandId);
          } catch(error: any) {
            helper.setSubmitting(false);
          }
        };

        submitData(updateReq);
    });


    useEffect(() => {
        const loadWife =async () => {
            const res:Wife = await GenerationService.getWife(props.wifeId);
            setWife(res);
        };

        loadWife();
    }, [props.wifeId]);

    const backLinkUrl = "/user-each/"+props.husbandId;


    return <div className="container">
        <Link className="btn btn-secondary btn-sm mb-3" to={backLinkUrl}><ArrowLeft /> Go Back</Link>

        {
          !wife? <div className="container position-relative"><LoadingSpinner class="loading-spinner-md"/></div>:
                <Card>
                    <Card.Header as="h4"><Link to={backLinkUrl}>{decodeURI(props.husbandName)}</Link> / Edit Wife</Card.Header>
                    <Card.Body>
                      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize
                        onSubmit={onFormSubmit}>
                          <TheWifeForm {...props} />
                      </Formik>
                    </Card.Body>
                </Card>
        }
    </div>
}

export default EditWifeForm;