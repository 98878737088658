import { Tag } from "primereact/tag";
import { CSSProperties } from "react";


interface GenProps {
    generationNo: number;
    style?: CSSProperties;
}

export const GenTag = (props: GenProps) => {
    return <Tag severity="info" className="genNoTag" style={props.style}>G{props.generationNo}</Tag>;
}

interface BirthRankProps {
    birthRank: number;
    style?: CSSProperties;
}

export const BirthRankTag = (props: BirthRankProps) => {
    return <Tag className="birthRankTag" style={props.style}>F{props.birthRank}</Tag>;
}