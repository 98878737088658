import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";


interface Props {
  children: any;
}

const RouteScrollToTop = (props:Props) => {
      const location = useLocation();
      const navType = useNavigationType();
      useEffect(() => {
        if (navType !== "POP") {
          setTimeout(() => window.scrollTo({top: 0,left: 0, behavior: 'auto'}), 10);
        }
      }, [location.pathname, navType]);

      return <>{props.children}</>;
};

export default RouteScrollToTop;
// export default withRouter(RouteScrollToTop);