
import { Individual } from '../models/Individual';

import Message from '../models/Message';
import { Wife } from '../models/Wife';
import { ParentUpdateReq } from '../models/ParentUpdateReq';
import HttpService from './HttpService';

 class GenerationService {
    _baseUrl: string = "/individual";
    
    
    async getIndividuals(generationNo: number): Promise<Individual[]> {
        return await HttpService.get(this._baseUrl + '/generation?generationNo='+generationNo);
    }

    async getIndividual(id: string, ...fields: string[]): Promise<Individual> {
        return await HttpService.get(this._baseUrl + '/'+id+ (fields.length > 0? "?fields="+fields.join(","): ''));
    }

    async exportIndividualsDetail(id: string) {
        const url = "/"+id+"/download";
        return await HttpService.getCustom(this._baseUrl + url, {responseType: 'blob'});
    }

    async getFather(userId: string): Promise<Individual> {
        return await HttpService.get(this._baseUrl + '/father?id='+userId);
    }

    async getDescendants(userId: string): Promise<Individual[]> {
        const url = this._baseUrl + '/descendants?id='+userId+"&maxDepth=10";
        return await HttpService.get(url);
    }

    async getAllIndividuals(): Promise<Individual[]> {
        return await HttpService.get(this._baseUrl + '/list');
    }

    async searchIndividuals(keyword: string): Promise<Individual[]> {
        return await HttpService.get(this._baseUrl + '/search?keyword='+keyword);
    }

    async exportIndividualsList(generationNo?: number) {
        let url = "/list/download";

        if(generationNo)
            url += "?generationNo="+generationNo;
        
        return await HttpService.getCustom(this._baseUrl + url, {responseType: 'blob'});
    }

    async getAllIndividualsPage(pageNo: number, pageSize:number,generationNo?: number): Promise<Individual[]> {
        let url = '/list/page?pageNo='+pageNo+"&pageSize="+pageSize;

        if(generationNo) {
            url += "&generationNo="+generationNo;
        }

        return await HttpService.get(this._baseUrl + url);
    }

    async getAllUnSyncedIndividuals(): Promise<Individual[]> {
        let url = '/list/unsynced';

        return await HttpService.get(this._baseUrl + url);
    }

    async addIndividual(individual: Individual): Promise<Individual> {
        return await HttpService.post(this._baseUrl + '/', individual);
    }

    async updateIndividual(userId: string, individual: Individual): Promise<Individual> {
        return await HttpService.post(this._baseUrl + '/'+userId+"/update", individual);
    }

    async updateParents(parentUpdateReq: ParentUpdateReq): Promise<Message> {
        return await HttpService.post(this._baseUrl + '/update/parents', parentUpdateReq);
    }

    async updateGenerationNo(userId: string, newGenNo: number): Promise<Message> {
        return await HttpService.post(this._baseUrl + '/'+userId+'/updateGenNo?newGen='+newGenNo, null);
    }

    async deleteIndividual(id: string): Promise<Message> {
        return await HttpService.delete(this._baseUrl + "/"+id);
    }

    async getWife(wifeId: string): Promise<Wife> {
        return await HttpService.get(this._baseUrl + '/wife?wifeId='+wifeId);
    }

    async addWife(userId: string, wife:Wife): Promise<Message> {
        return await HttpService.post(this._baseUrl + '/wife?individualId='+userId, wife);
    }

    async updateWife(wifeId: string, wife: Wife): Promise<Individual> {
        return await HttpService.post(this._baseUrl + '/wife/update?wifeId='+wifeId, wife);
    }


    async deleteWife(wifeId: string): Promise<Message> {
        return await HttpService.delete(this._baseUrl + '/wife/'+wifeId);
    }

    async uploadUnSyncedIndividuals(): Promise<Message> {
        return await HttpService.post(this._baseUrl + '/upload', null);
    }
}

export default new GenerationService();