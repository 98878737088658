export enum UserStatus {
    ACTIVE = "ACTIVE", INACTIVE = "INACTIVE"
}

export enum UserRole {
    MODERATOR = "MODERATOR", ADMIN = "ADMIN"
}

export default interface User {
    id?: string;
    fullName: string;
    phoneNumber?: string;
    password?: string;
    role?: UserRole;
    status?: UserStatus;
    expiresOn?: number;
}