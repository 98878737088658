import { createContext, useContext, useMemo, useState } from "react";
import User from "../../models/User";
import SessionService from "../../services/SessionService";


export const SessionContext = createContext({} as SessionContextType);

interface SessionContextType {
    sessionToken?: string;
    setSessionToken: (value: string) => void;
    removeSessionToken: () => void;
    isLoggedIn: () => boolean;
    getUser: () => User | null;
}

interface Props {
    children: any
}

const UserSessionHandler = (props: Props) => {
    const [token, setToken] = useState(SessionService.getToken());

    const contextPayload:SessionContextType = useMemo(() => {
        return {sessionToken: token!, 
            setSessionToken: (token) => {
                SessionService.saveSession(token);
                setToken(token);
            }, removeSessionToken: () => {
                SessionService.clearToken();
                setToken(null);
            }, isLoggedIn: () => {
                return SessionService.isLoggedIn();
            },
            getUser: () => {
                return SessionService.getUser();
            }
        }
    }, [token]);

    return <SessionContext.Provider value={contextPayload}>
        {props.children}
    </SessionContext.Provider>
};

export default UserSessionHandler;


export const useSession = () => {
    const sessionContext = useContext(SessionContext);

    return {sessionToken: sessionContext.sessionToken, 
            setSessionToken: sessionContext.setSessionToken,
            removeSessionToken: sessionContext.removeSessionToken,
            isLoggedIn: sessionContext.isLoggedIn,
            getUser: sessionContext.getUser};
}