import { Gender } from "../models/Individual";


interface DateValues {
    date: string;
    month: string;
    year: number;
}

interface EachMonth {
    name: string;
    value: string;
}

export class CommonUtil {

    static readonly MONTHS:EachMonth[] = [{name: "Baishak", value: "01"}, {name: "Jestha", value: "02"}, {name: "Ashar", value: "03"}, 
                                        {name: "Shrawan", value: "04"}, {name: "Bhadra", value: "05"}, {name: "Ashoj", value: "06"},
                                        {name: "Kartik", value: "07"}, {name: "Mangshir", value: "08"}, {name: "Poush", value: "09"},
                                        {name: "Magh", value: "10"}, {name: "Falgun", value: "11"}, {name: "Chaitra", value: "12"}];


    static getDOBStr(dobDate: string, dobMonth: string, dobYear: string) {
        return dobDate+"-"+dobMonth+"-"+dobYear;
    }

    static splitDOBStr(dobStr?: string):DateValues | null {
        if(!dobStr) return null;
        
        const data:string[] = dobStr.split("-");
        return {date: data[0], month: data[1], year: Number(data[2])} as DateValues;
    }

    static getDOBReadableStr(dobStr: string): string {
        const dobValues = CommonUtil.splitDOBStr(dobStr)!;

        const month = CommonUtil.MONTHS.filter(m => m.value === dobValues.month)[0];

        return dobValues.date+" "+month.name+", "+dobValues.year;
    }

    static toGenderStr(gender: Gender): string {
        if(gender === Gender.M) return 'Male';
        else if(gender === Gender.F) return 'Female';
        else return 'Other';
    }

    static fromGenderStr(arg: string): Gender {
        if(arg === Gender.M) return Gender.M;
        else if(arg === Gender.F) return Gender.F;
        else return Gender.O;
    }

    static getQueryParams(loc: {search: string}):Map<string, string> {
        const params:Map<string, string> = new Map();
        const queryParamStr = loc.search.substring(1).split("&");

        queryParamStr.forEach(q => {
            if(q) {
                const data = q.split("=");
                params.set(data[0], data[1]);
            }
        });
        return params;
    }

    static ordinalSuffixOf(i: number):string {
        const j = i % 10;
        const k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }
}