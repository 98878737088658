import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import Alert from 'react-bootstrap/Alert';

import Form from 'react-bootstrap/Form';
import { useSession } from "../components/common/UserSessionHandler";
import LoginSession from "../models/LoginSession";
import UserService from "../services/UserService";

const LoginPage = () => {
    const userSession = useSession();
    const [phone, setPhone] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setErrorMessage('');
    }, [phone, password]);
    
    const onLogin = (e:any) => {
        e.preventDefault();
        if(phone && password) {
            setSubmitting(true);
            UserService.login(Number(phone), password).then((res:LoginSession) => {
                userSession.setSessionToken(res.bearer);
                setSubmitting(false);
            }).catch(err => {
                setSubmitting(false);
            });
        } else {
            setErrorMessage('Please enter both phone number and password to login.');
        }
    };

    return <div className="container">
            <Card className="mx-auto my-5" style={{width: '40%'}}>
                <Card.Body>
                    <h2 className="text-center">Login</h2>
                    <Divider type="dashed" style={{width: '40%'}} className="mx-auto mt-4 mb-4" />

                    {
                        errorMessage &&
                        <Alert variant={"danger"} className="p-2">
                            <ExclamationCircle /> <small>{errorMessage}</small>
                        </Alert>
                    }
                    
                    <Form onSubmit={onLogin}>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="number" placeholder="E.g: 9841111111" value={phone} 
                                        onChange={(e) => {
                                            if(e.target.value) setPhone(e.target.value);
                                            else setPhone('');
                                        }} 
                                />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Button disabled={submitting} type="submit">{submitting? "Log In...": "Log In"}</Button>
                    </Form>
                </Card.Body> 
            </Card>
        </div>
    
};

export default LoginPage;